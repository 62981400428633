import { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function AnimalOptions(props) {
  // useEffect(() => {
  //   props.changePdf("/VisuallyDirectedFineMotor/AnimalShapes/Level1/AnimalShapesHELP.pdf");
  // }, []);

  const dirPath = "/VisuallyDirectedFineMotor/AnimalShapes/"

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Shape
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level1");
              // props.changePdf(
              //   "/VisuallyDirectedFineMotor/AnimalShapes/Level1/Circle1HELP.pdf",
              // );
              props.changeImage("Circle1");
            }}
            active={props.levelBool === "Level1"}
          >
            Circle
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level2");
              props.changeImage("Square1");
            }}
            active={props.levelBool === "Level2"}
          >
            Square
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level3");
              props.changeImage("Triangle1");
            }}
            active={props.levelBool === "Level3"}
          >
            Triangle
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level4");
              props.changeImage("Diamond1");
            }}
            active={props.levelBool === "Level4"}
          >
            Diamond
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.FlashRateDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Progression
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          {props.levelBool === "Level1" && (
            <div>
              <Dropdown.Item
                onClick={() => {props.changeImage("Circle1");}}
                active={props.imageBool === "Circle1"}
              >
                Level 1: Creations
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Circle2");}}
                active={props.imageBool === "Circle2"}
              >
                Level 2: Penguin
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Circle3");}}
                active={props.imageBool === "Circle3"}
              >
                Level 3: Koala
              </Dropdown.Item>
            </div>
          )}

          {props.levelBool === "Level2" && (
            <div>
              <Dropdown.Item
                onClick={() => {props.changeImage("Square1");}}
                active={props.imageBool === "Square1"}
              >
                Level 1: Stuff
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Square2");}}
                active={props.imageBool === "Square2"}
              >
                Level 2: Dog
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Square3");}}
                active={props.imageBool === "Square3"}
              >
                Level 3: Owl
              </Dropdown.Item>
            </div>
          )}
          {props.levelBool === "Level3" && (
            <div>
              <Dropdown.Item
                onClick={() => {props.changeImage("Triangle1");}}
                active={props.imageBool === "Triangle1"}
              >
                Level 1: Tricks
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Triangle2");}}
                active={props.imageBool === "Triangle2"}
              >
                Level 2: Cat
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Triangle3");}}
                active={props.imageBool === "Triangle3"}
              >
                Level 3: Hungry Koala
              </Dropdown.Item>
            </div>
          )}

          {props.levelBool === "Level4" && (
            <div>
              <Dropdown.Item
                onClick={() => {props.changeImage("Diamond1");}}
                active={props.imageBool === "Diamond1"}
              >
                Level 1: Sparkles
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Diamond2");}}
                active={props.imageBool === "Diamond2"}
              >
                Level 2: Angel Fish
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Diamond3");}}
                active={props.imageBool === "Diamond3"}
              >
                Level 3: Kookaburra
              </Dropdown.Item>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.hiddenLetterDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Hidden Letter
        </Dropdown.Toggle>
        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() =>
              props.hiddenLetter("/VisualThinking/FlashingPicturesLetters/")
            }
            active={
              props.letterBool === "/VisualThinking/FlashingPicturesLetters/"
            }
          >
            Use Hidden Letter
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              props.hiddenLetter("/VisualThinking/FlashingPictures/")
            }
            active={props.letterBool === "/VisualThinking/FlashingPictures/"}
          >
            Don't Use Hidden Letter
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
