export const activityNames = [
  "Picture Codes",
  "Clap Patterns",
  "Sound Codes",
  "Pointing",
  "Arrows",
  "Memory Dots",
  "Angels in the Snow",
  "Ball Bounce",
  "B, d, p, and q",
  "Halopin Hands",
  "Look in the Middle",
  "Halopin Hands 2",
  "Feel & Match",
  "Find the Picture",
  "Flashing Pictures",
  "Flashing Words",
  "Visual Sequence",
  "Flashing Dot Patterns",
  "Tangrams",
  "Flashing Shapes",
  "Word Twins",
  "Visual Sequence 2",
  "Say My Name",
  "Tangrams 2: The Sequel",
  "Flashing Blocks",
  "Flip & Rotate Shapes",
  "Describing Pictures",
  "Look Closely",
  "Picture Match",
  "Koala Copy",
  "Join the Circus",
  "Letter LeapFrog Charts",
  "Infinity Tracking",
  "Letter LeapFrog Songs",
  "Counting Letters & Words",
  "Letter LeapFrog Tales",
  "Finding Letters",
  "Bean Bag Swing",
  "Torch Tag",
  "Ball on String",
  "Walk the Plank",
  "Mirror World",
  "Bean Bag Toss",
  "3D Pictures",
  "Double Vision",
  "Beads on a String",
  "Magic Glasses",
  "Feed the Animals",
  "X-Ray Vision",
  "Super Vision",
  "Matching Colours",
  "Animal Shapes",
  "Pegboard Match",
  "Follow the Line",
  "Shadow Puppets",
  "Mazes",
  "Origami",
];
