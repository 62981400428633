export const HeroSection = () => {
  return (
    <div id="heroSection">
      <div id="heroTextContainer">
        <p id="heroSectionHeader">Welcome to Piggyback!</p>
        <div>
          <p className="boldHeader">Building readers one game at a time!</p>
          <p className="heroBodyText">
            Written words are visual codes for spoken language. Learning to
            decipher these symbols turns you into a reader. This is best done
            through joyful play.
          </p>
        </div>
        <div>
          <p className="boldHeader">Learning to read is different.</p>
          <p className="heroBodyText">
            Unlike walking and talking, reading does not come naturally. Like
            playing the piano it takes years to master. We create entire brain
            pathways to code language into written words!
          </p>
        </div>
        <div>
          <p className="boldHeader">
            Piggyback supports and guides development of:
          </p>
          <ul className="heroBodyText">
            <li>Coding symbols for meaning</li>
            <li>Efficient visual processing</li>
            <li>Eye teaming, focusing and movement</li>
          </ul>
        </div>
      </div>
      <img src="/home-page/koalaKangPic.png" />
    </div>
  );
};
