import { useState } from "react";
import "./UpperRightDrawer.css";
import { Document, Page } from "react-pdf";

import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

export default function UpperRightDrawer(props) {
  const [open, setOpen] = useState(false);
  const pdf = props.pdf.includes("Origami") ? props.pdf : props.pdf.slice(0, -4) + "LU.pdf"
  console.log(props.pdf.slice(0, -4));

  return (
    <div
      className={`closedDrawerUpperRight ${open ? "openDrawerUpperRight" : ""}`}
    >
      {!open ? (
        <div id="levelDrawer">
          <div className="openText" onClick={() => setOpen(!open)}>
            <p style={{ padding: "0.25rem" }}>
              <AiOutlineLeft size={20} />
            </p>
            <p className="sideText">Level Up!</p>
          </div>
        </div>
      ) : (
        <div id="openLevelDrawer">
          <div className="openText" onClick={() => setOpen(!open)}>
            <p style={{ padding: "0.25rem" }}>
              <AiOutlineRight />
            </p>
            <p className="sideText">Collapse</p>
          </div>
          <Document file={pdf}>
            <Page pageNumber={1} />
          </Document>
        </div>
      )}
    </div>
  );
}
