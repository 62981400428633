import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function CodedMovementButtons(props) {
  return (
    <div style={{ display: "flex" }}>
      <Dropdown className="dropDownStyle">
        <Dropdown.Toggle style={toggleStyle}>Flash</Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => props.flash(false)}
            active={!props.flashBool}
          >
            No Flash
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.flash(true)}
            active={props.flashBool}
          >
            Flashing Pictures
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="dropDownStyle">
        <Dropdown.Toggle style={toggleStyle}>Number</Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => props.number(1)}
            active={props.numberBool === 1}
          >
            1 Picture
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.number(2)}
            active={props.numberBool === 2}
          >
            2 Pictures
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.number(3)}
            active={props.numberBool === 3}
          >
            3 Pictures
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
