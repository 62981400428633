import "./ActivityContainer.css";
import ActivityHolder from "./ActivityHolder";
import { useState } from "react";

export default function ActivityContainer(props) {
  const [open, setOpen] = useState(false);

  const isActivityMagicGlasses =
    (props.activity === "/BuildingBinocularity/MagicGlasses.pdf") || (props.activity === "/VisuallyDirectedFineMotor/ShadowPuppets.pdf") || (props.activity === "/VisuallyDirectedFineMotor/AnimalShapes.pdf") || (props.activity === "/VisuallyDirectedFineMotor/Origami.pdf") || (props.activity === "/BuildingBinocularity/X-RayVision.pdf");

  const activityViewStyle1 = {
    backgroundColor: "white",
    border: "solid",
  };
  const activityViewStyle2 = {
    backgroundColor: "#191c26",
  };

  const iconStyle1 = {
    marginRight: "20px",
    marginTop: "25px",
    filter: "invert(100%)",
  };

  const iconStyle2 = { marginRight: "20px", marginTop: "25px" };

  return (
    <div>
      <div
        className={`activityView ${open ? "expanded" : ""}`}
        style={isActivityMagicGlasses ? activityViewStyle1 : activityViewStyle2}
      >
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <img
            src={open ? "shrink.png" : "/expand.png"}
            style={isActivityMagicGlasses ? iconStyle1 : iconStyle2}
          />
        </div>
        <ActivityHolder
          expanded={open}
          activity={props.activity}
          openActivity={setOpen}
          changePdf={props.changePdf}
          changeLU={props.changeLU}
        />
      </div>
    </div>
  );
}
