import "./BottomRightDrawer.css";
import { useState } from "react";
import { Document, Page } from "react-pdf";

import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

export default function BottomRightDrawer(props) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`closedDrawerBottomRight ${
        open ? "openDrawerbottomRight" : ""
      }`}
    >
      {!open ? (
        <div id="helpDrawer">
          <div className="openText" onClick={() => setOpen(!open)}>
            <p style={{ padding: "0.25rem" }}>
              <AiOutlineLeft />
            </p>
            <p className="sideText">Help</p>
          </div>
        </div>
      ) : (
        <div className="openHelpDrawer">
          <div className="openText" onClick={() => setOpen(!open)}>
          <p style={{ padding: "0.25rem" }}>
              <AiOutlineRight />
            </p>
            <p className="sideText">Collapse</p>
          </div>
          <div style={{ overflow: "hidden" }}>
            <Document file={props.pdf}>
              <Page pageNumber={1} />
            </Document>
            {/* <div style={{ marginTop: "40px" }}>
              <div>Help</div>
              <div>Browse our FAQ's</div>
              <p>
                Please browse our section information on any immediate Concerns.
              </p>
              <button>Go To FAQ's</button>
            </div>
            <div>
              <div>Send Us Your Questions</div>
              <p>
                If your questions and concerns are not addressed in our FAQ's,
                please submit your feedback below and we will get back to you
                ASAP
              </p>
              <p>Issue Type</p>
              <input type="text" />
              <p>Issue Description</p>
              <input type="text" />
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
