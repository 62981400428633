import TangramOptions from "./TangramOptions";
import { useEffect, useState } from "react";

export default function Level5_Tangrams(props) {
  var [time, setTime] = useState(1000);
  var [image, setImage] = useState("House");
  var [dirPath, setDirPath] = useState("/Visualization/Tangrams/");

  const [level, setLevel] = useState("Level1");
  const [display, setDisplay] = useState(1);
  const [next, setNext] = useState(false);
  const [check, setCheck] = useState(true);

  const Level4 = [
    "Crocodile",
    "Dinosaur",
    "Wombat",
    "Pengunin",
    "Frilled Lizard",
    "Emu",
    "Dog",
  ];
  const Level1 = ["Alien", "Tugboat", "House", "Bird", "Volcano"];
  const Level2 = ["Bat", "Butterfly", "Fish", "Platypus", "Koala", "Sugar Glider"];
  const Level3 = ["Pelican", "Kangaroo", "Sea Tutle", "Dingo"];

  function preLoadImages() {
    for (let i = 0; i < 4; i++) {
      if (i === 0) {
        loadLevel(i, Level1);
      }
      if (i === 1) {
        loadLevel(i, Level2);
      }
      if (i === 2) {
        loadLevel(i, Level3);
      }
      if (i === 3) {
        loadLevel(i, Level4);
      }
    }
  }

  function loadLevel(level, levelList) {
    for (let i = 0; i < levelList.Length; i++) {
      const img = new Image();
      const img2 = new Image();
      img.src =
        "/Visualization/Tangrams/" + level + "/" + levelList[i] + ".png";
      img2.src =
        "/Visualization/Tangrams/" + level + "/" + levelList[i] + "Answer.png";

      if (level <= 2) {
        const img3 = new Image();
        img3.src =
          "/Visualization/Tangrams/" + level + levelList[i] + "Pieces.png";
      }
    }
  }

  useEffect(() => {
    preLoadImages();
  }, []);

  useEffect(() => {
    setDisplay(1);
    setCheck(true);
    setNext(false);
    if (level !== "Level1") {
      props.changePdf(dirPath + "/" + level + "/" + image + "HELP.pdf");
    } else {
      props.changePdf(dirPath + "/" + level + "/TangramLevelOneHELP.pdf");
    }
  }, [level, image]);

  function displayPicture(buttonName) {
    if (buttonName !== "check") {
      setDisplay(1);
      setNext(true);
      setCheck(false);
    } else {
      setNext(false);
      setCheck(true);
      setDisplay(2);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <TangramOptions
          flashRate={setTime}
          flashBool={time}
          changeLevel={setLevel}
          levelBool={level}
          hiddenLetter={setDirPath}
          letterBool={dirPath}
          hiddenLetterDisplay="none"
          levelDisplay={dirPath + "Tangrams"}
          changeImage={setImage}
          imageBool={image}
          changePdf={props.changePdf}
        />
      </div>
      {props.open && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90%",
          }}
        >
          {display === 1 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "90%",
                width: "85%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: '100%'
                }}
              >
                <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
                  <div style={{ color: "white", fontSize: "3em" }}>
                    Where do the blocks go?
                  </div>
                  <img
                    style={{
                      width: "80%",
                      backgroundColor: "white",
                      padding: "10px",
                    }}
                    src={
                      level === "Level1" || level === "Level2"
                        ? dirPath + "/" + level + "/" + image + "Pieces" + ".png"
                        : dirPath + "/" + level + "/Level3_4Pieces.png"
                    }
                  />
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent:'center', alignItems:'center', height: '100%'}}>
                  <img
                    style={{ height: "100%" }}
                    src={dirPath + "/" + level + "/" + image + ".png"}
                  />
                </div>
                
                
              </div>
            </div>
          )}
          {display === 2 && (
            <div style={{ textAlign: "center", height: '100%', backgroundColor: 'red' }}>
              <img
                style={{ height: "100%" }}
                src={dirPath + "/" + level + "/" + image + "Answer" + ".png"}
              />
            </div>
          )}
        </div>
      )}
      <div className="activityActionButtonsContainer">
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              displayPicture("check");
            } else {
              props.openActivity(true);
            }
          }}
          // disabled={check}
        >
          Check
        </button>
        {/* <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              displayPicture("next");
            } else {
              props.openActivity(true);
            }
          }}
          disabled={next}
        >
          Next
        </button> */}
      </div>
    </div>
  );
}
