export const instructionMap = {
    "Butterfly1": "Palms towards you",
    "Butterfly2": "Cross hands",
    "Butterfly3": "Link thumbs",
    "Duck1": "Thumb to first finger",
    "Duck2": "Straighten fingers",
    "Duck3": "Pinky finger down",
    "Dog1": "Hold your hand flat",
    "Dog2": "Raise your thumb",
    "Dog3": "Bend your finger",
    "Dog4": "Pinky down",
    "Crab1": "Palms face you",
    "Crab2": "Cross hands",
    "Crab3": "Spread fingers & bend thumbs",
    "Crab4": "Claw fingers 3 on each side",
    "Croc1": "Top hand: \n curl down last 3 fingers",
    "Croc2": "Bottom hand: \n curl up thumb and last 2 fingers adjust you hands to get the teeth and head just right.",
    "Jellyfish1": "Top hand flat",
    "Jellyfish2": "bend fingers down",
    "Jellyfish3": "Other hand grabs thumb Fingers dangle to make the jellyfish tentacles",
    "Kookaburra1": "Make the branch with hand and first 2 fingers bend ring and pinky fingers to make the tail feathers",
    "Kookaburra2": "Hold other hand flat, bend first finger to form the eye",
    "Kookaburra3": "Bend last 2 fingers so middle finger forms the beak",
    "Kookaburra4": "Lay your front hand on top of the other, rotating until you get the angle to form the bird.",
    "Dogs1": "Hands together",
    "Dogs2": "Cross first fingers",
    "Dogs3": "Pinky fingers down",
}