import Level1_FlashingPictures from "../Activities/Visualisation/Level1_FlashingPictures";
import Intro_VisuallyCodedMovement from "../Activities/Coding/Intro_VisuallyCodedMovement";
import Level2_FlashingClapPatterns from "../Activities/Coding/Level2_FlashingClapPatterns";
import Level2_SoundCodes from "../Activities/Coding/Level2_SoundCodes";
import Level6_Arrows from "../Activities/Coding/Level6_Arrows";
import Level5_MemoryDots from "../Activities/Coding/Level5_MemoryDots";
import Level8_bdpandq from "../Activities/Coding/Level8_bdpandq";
import Level4_VisualSequence from "../Activities/Visualisation/Level4_VisualSequence";
import Level3_RememberingShapes from "../Activities/Visualisation/Level3_RememberingShapes";
import Level5_FlashingWords from "../Activities/Visualisation/Level5_FlashingWords";
import Level5_FlashingDotToDotPatterns from "../Activities/Visualisation/Level5_FlashingDotToDotPatterns";
import Level5_RememberingBlockPatterns from "../Activities/Visualisation/Level5_RememberingBlockPatterns";
import Level2_FindThePicture from "../Activities/Visualisation/Level2_FindThePicture";
import Level2_NamingPictures from "../Activities/EyeMovements/Level2_NamingPictures";
import Level5_LetterCharts from "../Activities/EyeMovements/Level5_LetterCharts";
import Level6_LetterCharts2 from "../Activities/EyeMovements/Level6_LetterCharts2";
import Level2_Pointing from "../Activities/Coding/Level2_Pointing";
import Level5_BallBounce from "../Activities/Coding/Level5_BallBounce";
import Level8_TheShortVowelSounds from "../Activities/Coding/Level8_TheShortVowelSound";
import Level6_FlashingWords2 from "../Activities/Visualisation/Level6_FlashingWords2";
import Level6_HalopinHands from "../Activities/Coding/Level6_HalopinHands";
import Level7_HalopinHands2 from "../Activities/Coding/Level7_HalopinHands2";
import Level6_SayMyName from "../Activities/Visualisation/Level6_SayMyName";
import Level8_FlippingandRotatingShapes from "../Activities/Visualisation/Level8_FlippingandRotatingShapes";
import Level7_DescribingPictures from "../Activities/Visualisation/Level7_DescribingPictures";
import Level7_LetterCharts3 from "../Activities/EyeMovements/Level7_LetterCharts3";
import Level4_CountingLetters from "../Activities/EyeMovements/Level4_CountingLetters";
import Level8_FindingLetters from "../Activities/EyeMovements/Level8_FindingLetters";
import Level7_SuperVision from "../Activities/BuildingBinocularity/Level7_SuperVision";
import Level1_FindTheShape from "../Activities/EyeMovements/Level1_FindTheShape";
import Level5_MemoryDotsPeg from "../Activities/VisuallyDirectedFineMotor/Level5_MemoryDotsPeg";
import Level5_Tangrams from "../Activities/Visualisation/Level5_Tangrams";
import Level6_Mazes from "../Activities/VisuallyDirectedFineMotor/Level6_Mazes";
import Level4_FollowtheLine from "../Activities/VisuallyDirectedFineMotor/Level4_FollowtheLine";
import Level6_MagicGlasses from "../Activities/BuildingBinocularity/Level6_MagicGlasses";
import Level5_FeedTheAnimals from "../Activities/BuildingBinocularity/Level5_FeedTheAnimals";
import Level4_DoubleVision from "../Activities/BuildingBinocularity/Level4_DoubleVision";
import Level4_3DPictures from "../Activities/BuildingBinocularity/Level4_3DPictures";
import Level1_AnimalShapes from "../Activities/VisuallyDirectedFineMotor/Level1_AnimalShapes";
import Level6_XRayVision from "../Activities/BuildingBinocularity/Level6_XRayVision";
import Level3_ShadowPuppets from "../Activities/VisuallyDirectedFineMotor/Level3_ShadowPuppets";
import Level7_Origrami from "../Activities/VisuallyDirectedFineMotor/Level7_Origami";
import Level4_VisualSequence2 from "../Activities/Visualisation/Level4_VisualSequence2";
import Level5_Tangrams2 from "../Activities/Visualisation/Level5_Tangrams2";

export default function ActivityHolder(props) {
  return (
    <div
      style={{
        height: "754px",
        border: "solid",
        borderRadius: "25px",
        borderColor: "transparent",
        padding: "15px",
      }}
    >
      {props.activity === "/Coding/PictureCodes.pdf" && (
        <Intro_VisuallyCodedMovement
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Coding/ClapPatterns.pdf" && (
        <Level2_FlashingClapPatterns
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Coding/SoundCodes.pdf" && (
        <Level2_SoundCodes
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Coding/Arrows.pdf" && (
        <Level6_Arrows
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Coding/MemoryDots.pdf" && (
        <Level5_MemoryDots
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Coding/bdpq.pdf" && (
        <Level8_bdpandq
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Visualization/FlashingPictures.pdf" && (
        <Level1_FlashingPictures
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Visualization/VisualSequence.pdf" && (
        <Level4_VisualSequence
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Visualization/FlashingShapes.pdf" && (
        <Level3_RememberingShapes
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Visualization/FlashingWords.pdf" && (
        <Level5_FlashingWords
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Visualization/FlashingDotPatterns.pdf" && (
        <Level5_FlashingDotToDotPatterns
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Visualization/FlashingBlocks.pdf" && (
        <Level5_RememberingBlockPatterns
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Visualization/FindthePicture.pdf" && (
        <Level2_FindThePicture
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/EyeMovements/PictureMatch.pdf" && (
        <Level2_NamingPictures
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/EyeMovements/LetterLeapFrogCharts.pdf" && (
        <Level5_LetterCharts
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/EyeMovements/LetterLeapFrogSongs.pdf" && (
        <Level6_LetterCharts2
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Coding/Pointing.pdf" && (
        <Level2_Pointing
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Coding/BallBounce.pdf" && (
        <Level5_BallBounce
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Coding/LookintheMiddle.pdf" && (
        <Level8_TheShortVowelSounds
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Visualization/WordTwins.pdf" && (
        <Level6_FlashingWords2
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Coding/HalopinHands.pdf" && (
        <Level6_HalopinHands
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Coding/HalopinHands2.pdf" && (
        <Level7_HalopinHands2
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Visualization/SayMyName.pdf" && (
        <Level6_SayMyName
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Visualization/Flip&RotateShapes.pdf" && (
        <Level8_FlippingandRotatingShapes
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Visualization/DescribingPictures.pdf" && (
        <Level7_DescribingPictures
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/EyeMovements/LetterLeapFrogTales.pdf" && (
        <Level7_LetterCharts3
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/EyeMovements/CountingLetters&Words.pdf" && (
        <Level4_CountingLetters
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/EyeMovements/FindingLetters.pdf" && (
        <Level8_FindingLetters
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/BuildingBinocularity/SuperVision.pdf" &&
        props.expanded && (
          <Level7_SuperVision
            open={props.expanded}
            openActivity={props.openActivity}
          />
        )}
      {props.activity === "/EyeMovements/KoalaCopy.pdf" && (
        <Level1_FindTheShape
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/VisuallyDirectedFineMotor/PegboardMatch.pdf" && (
        <Level5_MemoryDotsPeg
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/Visualization/Tangrams.pdf" && (
        <Level5_Tangrams
          changePdf={props.changePdf}
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/VisuallyDirectedFineMotor/Mazes.pdf" && (
        <Level6_Mazes
          changePdf={props.changePdf}
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/VisuallyDirectedFineMotor/FollowtheLine.pdf" && (
        <Level4_FollowtheLine
          changePdf={props.changePdf}
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/BuildingBinocularity/MagicGlasses.pdf" && (
        <Level6_MagicGlasses
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/BuildingBinocularity/FeedtheAnimals.pdf" && (
        <Level5_FeedTheAnimals
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/BuildingBinocularity/DoubleVision.pdf" && (
        <Level4_DoubleVision
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/BuildingBinocularity/3DPictures.pdf" && (
        <Level4_3DPictures
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/VisuallyDirectedFineMotor/AnimalShapes.pdf" && (
        <Level1_AnimalShapes
          changePdf={props.changePdf}
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/BuildingBinocularity/X-RayVision.pdf" && (
        <Level6_XRayVision
          changePdf={props.changePdf}
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/VisuallyDirectedFineMotor/ShadowPuppets.pdf" && (
        <Level3_ShadowPuppets
          changePdf={props.changePdf}
          open={props.expanded}
          openActivity={props.openActivity}
        />
      )}
      {props.activity === "/VisuallyDirectedFineMotor/Origami.pdf" && (
        <Level7_Origrami
          changePdf={props.changePdf}
          open={props.expanded}
          openActivity={props.openActivity}
          changeLU={props.changeLU}
        />
      )}
      {props.activity === "/Visualization/VisualSequence2.pdf" && (
        <Level4_VisualSequence2
          changePdf={props.changePdf}
          open={props.expanded}
          openActivity={props.openActivity}
          changeLU={props.changeLU}
        />
      )}
      {props.activity === "/Visualization/Tangrams2:TheSequel.pdf" && (
        <Level5_Tangrams2
          changePdf={props.changePdf}
          open={props.expanded}
          openActivity={props.openActivity}
          changeLU={props.changeLU}
        />
      )}
    </div>
  );
}
