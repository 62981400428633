// import XRayButtons from "./XRayButtons";
import { useEffect, useState } from "react";

export default function Level6_XRayVision(props) {
  var [image1, setImage1] = useState("Croc");
  var [dirPath, setDirPath] = useState("/BuildingBinocularity/");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: 'space-around',
        alignItems: 'center',
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      {props.open && (
          <img
            className="flashingPicsImage"
            src={dirPath + image1 + ".png"}
          />
      )}
      {props.open && <div style={{width: '100%', textAlign: 'end'}}>
        <button className="activityActionButton" onClick={() => image1 === "Croc" ? setImage1(image1 + "Answer") : setImage1("Croc")}>{image1 === "Croc" ? "Check" : "Back"}</button>
      </div>}
    </div>
  );
}
