import FlashingPictureButtons from "./wordListComponent";
import { useState, useRef, useEffect } from "react";
import {
  BlendTriple,
  BlendsDouble,
  SmallWordsDouble,
  SmallWordsTriple,
} from "./wordList";
import Highlighter from "react-highlight-words";

export default function Level1_FlashingPictures(props) {
  var [displayTime, setDisplayTime] = useState("hidden");
  var [time, setTime] = useState(1000);
  var [rawTextArray, setRawTextArray] = useState(SmallWordsDouble);
  var [wordList, setWordList] = useState("small");
  var [number, setNumber] = useState(2);
  var [disable, setDisable] = useState(true);
  const [display, setDisplay] = useState("none");
  const [mode, setMode] = useState("highlight");

  const image13Ref = useRef();

  const myRefs = [image13Ref];

  var [image13, setImage13] = useState("");

  useEffect(() => {
    setDisplayTime("hidden");
    invisible();
    setDisable(true);
  }, [time, wordList, number, mode]);

  function handleWordFlashing() {
    if (image13Ref.current) {
      invisible();
      setDisable(true);
      setImage13(rawTextArray[Math.floor(Math.random() * rawTextArray.length)]);
      if (mode === "highlight") {
        setDisplay("block");
        if (time !== 0) {
          setTimeout(() => {
            setDisplay("none");
            setDisable(false);
          }, time);
        }
      } else {
        if (time !== 0) {
          setTimeout(() => {
            image13Ref.current.style.visibility = "hidden";
            setDisable(false);
          }, time);
        }
        image13Ref.current.style.visibility = "visible";
      }
    }
  }

  function invisible() {
    setDisplay("none");
    for (let i = 0; i < myRefs.length; i++) {
      if (myRefs[i].current) {
        myRefs[i].current.style.visibility = "hidden";
      }
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <FlashingPictureButtons
          flashRate={setTime}
          flashBool={time}
          hiddenLetterDisplay="block"
          changeList={setWordList}
          wordList={wordList}
          number={number}
          changeNumber={setNumber}
          changeArray={setRawTextArray}
          changeFlash={setDisable}
          mode={mode}
          changeMode={setMode}
        />
      </div>
      {props.open && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div className="whiteStyle">
            <div
              ref={image13Ref}
              style={{ display: "inline", visibility: displayTime }}
            >
              {image13 + " "}
            </div>
          </div>
          <div style={{ display: display }}>
            <Highlighter
              style={{ color: "white" }}
              highlightClassName="matchingVowels"
              unhighlightClassName="unmatchedLetters"
              searchWords={["a", "e", "i", "o", "u", "y"]}
              autoEscape={true}
              textToHighlight={image13}
            />
          </div>
        </div>
      )}
      <div className="activityActionButtonsContainer">
        <button
          className="activityActionButton"
          disabled={disable || time === 0}
          onClick={() => {
            if (props.open) {
              if (mode === "highlight") {
                setDisplay("block");
              } else {
                image13Ref.current.style.visibility = "visible";
              }
              if (time === 0) {
                image13Ref.current.style.visibility = "hidden";
              }
            } else {
              props.openActivity(true);
            }
          }}
        >
          Check
        </button>
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              handleWordFlashing();
              // if (time === 0) {
              //   setDisable(false)
              // }
            } else {
              props.openActivity(true);
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}
