import "./LeftDrawer.css";
import { useState } from "react";
import { ActivityTable } from "../activity-table";

import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

export default function LeftDrawer(props) {
  const [open, setOpen] = useState(false);

  return (
    <div className={`closed_drawer ${open ? "open_drawer" : ""}`}>
      {!open ? (
        <div className="closedDrawerLeft">
          <div className="openText" onClick={() => setOpen(!open)}>
            <p style={{ padding: "0.25rem" }}>
              <AiOutlineRight />
            </p>
            <p className="sideText">Activities</p>
          </div>
        </div>
      ) : (
        <div id="openLeftDrawer">
          <div style={{ marginTop: "40px" }}>
            <ActivityTable
              changePdf={props.changePdf}
              changePdf2={props.changePdf2}
              closeDrawer={setOpen}
            />
          </div>
          <div className="openText" onClick={() => setOpen(!open)}>
            <p style={{ padding: "0.25rem" }}>
              <AiOutlineLeft />
            </p>
            <p className="sideText">Collapse</p>
          </div>
        </div>
      )}
    </div>
  );
}
