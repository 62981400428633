import { useEffect, useState, useRef, createRef } from "react";
import LetterChartButtons from "./letterChartButtons";
import useSound from "use-sound";
import {
  SingleA,
  SingleB,
  SingleC,
  SingleD,
  DoubleA,
  DoubleB,
  DoubleC,
  DoubleD,
} from "./LetterChartWords";

export default function Level5_LetterCharts(props) {
  var [grid, setGrid] = useState(3);
  var [chart, setChart] = useState([]);
  var [wordList, setWordList] = useState(DoubleA);
  var [beat, setBeat] = useState(1000);
  var [sound, setSound] = useState(false);
  var [fontCase, setFontCase] = useState("lowercase");
  var [mode, setMode] = useState("Off");
  var [size, setSize] = useState("36pt");
  var [spacing, setSpacing] = useState("32px");
  var [intervalId, setIntervalId] = useState(0);
  var alph = "abcdefghijklmnopqrstuvwxyz";
  var selected = [];
  var place = 0;
  var [testTime, setTestTime] = useState(0);
  var [running, setRunning] = useState(false);
  const [counter, setCounter] = useState(0);

  const [play] = useSound("/clickSound.wav");

  const [column, setColumn] = useState("3em");

  const myRefs = useRef([]);

  let count = 0;

  useEffect(() => {
    if (intervalId) {
      stopFunction();
      setChart([]);
      clearTimeout(testTime);
      setRunning(false);
    }
    // if (mode === "Off") {
    //   setChart([]);
    // }
    setChart([]);
  }, [grid, fontCase, size, mode, spacing, beat, sound, wordList]);

  useEffect(() => {
    var highestTimeoutId = setTimeout(";");
    for (var i = 0; i < highestTimeoutId; i++) {
      clearTimeout(i);
      clearInterval(i);
    }
  }, [props.change]);

  useEffect(() => {
    console.log("im in the useEffect cuz wordList changed");
    getCount();
  }, [wordList]);

  function stopFunction() {
    clearInterval(intervalId);
    setIntervalId(0);
  }

  function getCount() {
    let tempCount = 0;
    for (let i = 0; i < wordList.length; i++) {
      for (let j = 0; j < wordList[i].length; j++) {
        tempCount++;
      }
    }
    console.log("this is the temp count: ", tempCount);
    setCounter(tempCount);
  }

  function selectedLetters() {
    // let count = 25;
    for (let i = 0; i < counter; i++) {
      selected.push(i);
    }
  }

  function clearOut(index) {
    let test = setTimeout(() => {
      myRefs.current[index].current.style.color = "white";
    }, 500);
    setTestTime(test);
  }

  function triggerGreen() {
    setRunning(true);
    var color = setInterval(() => {
      if (sound) {
        play();
      }
      myRefs.current[place].current.style.color = "greenyellow";
      clearOut(place);
      place++;
      if (place === counter) {
        clearInterval(color);
        setRunning(false);
      }
    }, beat);
    setIntervalId(color);
  }

  function reset() {
    setChart([]);
    if (running) {
      clearInterval(intervalId);
      clearInterval(testTime);
    }
    setTimeout(() => {
      createRow();
    }, 250);
  }

  function CreateLetters(place) {
    let temp = [];
    for (let i = 0; i < wordList[place].length; i++) {
      temp.push(
        <div
          key={count}
          style={{ marginRight: spacing, width: column, textAlign: "left" }}
          className="cell"
        >
          <span ref={myRefs.current[count]}>{wordList[place][i][0]}</span>
          <span>{wordList[place][i].slice(1)}</span>
        </div>,
      );
      count++;
    }
    return temp;
  }

  function createRow() {
    selectedLetters();
    myRefs.current = selected.map(
      (element, i) => myRefs.current[i] ?? createRef(),
    );
    let tempChart = [];
    for (let i = 0; i < wordList.length; i++) {
      tempChart.push(
        <div
          key={i}
          className="rowChart"
          style={{
            // textTransform: fontCase,
            fontSize: size,
            textAlign: "center",
          }}
        >
          {CreateLetters(i)}
        </div>,
      );
    }
    setChart(tempChart);
    console.log("myrefs: ", myRefs.current);
    count = 0;
    if (mode !== "Off") {
      triggerGreen();
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <LetterChartButtons
          flashRate={setBeat}
          flashBool={beat}
          ChangeRow={setGrid}
          rowBool={grid}
          changeMode={setFontCase}
          modeBool={fontCase}
          changeSize={setSize}
          sizeBool={size}
          changeFlash={setMode}
          triggerBool={mode}
          changeSpacing={setSpacing}
          spaceBool={spacing}
          changeSound={setSound}
          soundBool={sound}
          wordList={wordList}
          changeWordList={setWordList}
          changeWidth={setColumn}
          currWidth={column}
        />
      </div>
      {props.open && <div style={{ margin: "auto" }}>{chart}</div>}
      <div className="activityActionButtonsContainer">
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              reset();
            } else {
              props.openActivity(true);
            }
          }}
        >
          Start
        </button>
      </div>
    </div>
  );
}
