export default function NextCheckCountLetter(props) {
  return (
    <div className="activityActionButtonsContainer">
      <button
        className="activityActionButton"
        onClick={() => {
          if (props.open) {
            props.displayAnswer(true);
          } else {
            props.openActivity(true);
          }
        }}
        style={{ display: props.displayCheck }}
        disabled={props.disableCheck}
      >
        Check
      </button>
      <button
        className="activityActionButton"
        onClick={() => {
          if (props.open) {
            props.displayFunc();
          } else {
            props.openActivity(true);
          }
        }}
      >
        Next
      </button>
    </div>
  );
}
