import { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function ThreeDPicturesOptions(props) {
  useEffect(() => {
    // props.changePdf("/Visualization/Tangrams/Level1/TangramLevelOneHELP.pdf");
  }, []);

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Progression
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level1");
              // props.changePdf(
              //   "/Visualization/Tangrams/Level1/TangramLevelOneHELP.pdf",
              // );
              props.changeImage("sugar");
            }}
            active={props.levelBool === "Level1"}
          >
            Level 1: What do you see?
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level2");
              props.changeImage("ball");
            }}
            active={props.levelBool === "Level2"}
          >
            Level 2: What will happen?
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level3");
              props.changeImage("meadow");
            }}
            active={props.levelBool === "Level3"}
          >
            Level 3: Where is everything?
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.FlashRateDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Picture
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          {props.levelBool === "Level1" && (
            <div>
              <Dropdown.Item
                onClick={() => props.changeImage("sugar")}
                active={props.imageBool === "sugar"}
              >
                Sugar glider
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("dinosaur")}
                active={props.imageBool === "dinosaur"}
              >
                Dinosaur
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("kangaroo")}
                active={props.imageBool === "kangaroo"}
              >
                Kangaroo Family
              </Dropdown.Item>
            </div>
          )}

          {props.levelBool === "Level2" && (
            <div>
              <Dropdown.Item
                onClick={() => props.changeImage("ball")}
                active={props.imageBool === "ball"}
              >
                Who will catch the ball?
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("race")}
                active={props.imageBool === "race"}
              >
                Who will win the race?
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("worm")}
                active={props.imageBool === "worm"}
              >
                Who will catch the worm?
              </Dropdown.Item>
            </div>
          )}
          {props.levelBool === "Level3" && (
            <div>
              <Dropdown.Item
                onClick={() => props.changeImage("meadow")}
                active={props.imageBool === "meadow"}
              >
                Who is in the meadow?
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("planets")}
                active={props.imageBool === "planets"}
              >
                Where are the planets?
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("animals")}
                active={props.imageBool === "animals"}
              >
                What animals can you find?
              </Dropdown.Item>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.hiddenLetterDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Hidden Letter
        </Dropdown.Toggle>
        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() =>
              props.hiddenLetter("/VisualThinking/FlashingPicturesLetters/")
            }
            active={
              props.letterBool === "/VisualThinking/FlashingPicturesLetters/"
            }
          >
            Use Hidden Letter
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              props.hiddenLetter("/VisualThinking/FlashingPictures/")
            }
            active={props.letterBool === "/VisualThinking/FlashingPictures/"}
          >
            Don't Use Hidden Letter
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
