import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Auth";

import { signOut } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db, auth } from "../../base";

import { AiOutlineLeft as BackIcon } from "react-icons/ai";

import { spellOutDate } from "../../utils/formatDate";

function LicenseInformation(props) {
  const licenseCanBeActivated = props.showLicenseModal && !props.activated;

  return (
    <div className="licenseInformationContainer">
      <p
        className={licenseCanBeActivated ? "licenseCode" : ""}
        onClick={() => {
          if (licenseCanBeActivated) {
            props.setLicenseCode(props.license);
          }
        }}
      >
        {props.license}
      </p>
      <p>{props.activated ? "Yes" : "No"}</p>
      <p>{props.email && props.email}</p>
      <p>
        {props.experationDate &&
          spellOutDate(new Date(props.experationDate.seconds * 1000))}
      </p>
    </div>
  );
}

export function ProviderAccount(props) {
  const { currentUser, authUserData, setAuthUserData } = useContext(AuthContext);

  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(props.userData.firstName);
  const [lastName, setLastName] = useState(props.userData.lastName);
  const [email, setEmail] = useState(props.userData.email);
  const [practice, setPractice] = useState(props.userData.practice);
  const [street, setStreet] = useState(props.userData.street);
  const [city, setCity] = useState(props.userData.city);
  const [country, setCountry] = useState(props.userData.country);
  const [zipCode, setZipCode] = useState(props.userData.zipCode);

  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const [licenseCode, setLicenseCode] = useState("");

  const handleEditInformation = async (e) => {
    e.preventDefault();
    props.setLoading(true);
    const userDocRef = doc(db, "users", props.userData.userID);
    await setDoc(
      userDocRef,
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        practice: practice,
        street: street,
        city: city,
        country: country,
        zipCode: zipCode,
      },
      { merge: true },
    );
    const userDocSnap = await getDoc(userDocRef);
    props.setUserData(userDocSnap.data());
    props.setLoading(false);
  };

  async function handleLicenseActivation() {
    props.setLoading(true);

    const licenceDocRef = doc(db, "licenses", licenseCode);
    const userDocRef = doc(db, "users", currentUser.uid);

    const [licenceDocSnap, userDocSnap] = await Promise.all([
      getDoc(licenceDocRef),
      getDoc(userDocRef),
    ]);

    // basic validation
    if (!licenceDocSnap.exists()) {
      alert("The license code you provided could not be found.");
      props.setLoading(false);
      return;
    }

    if (licenceDocSnap.data().used) {
      alert("The license code you provided has already been used.");
      props.setLoading(false);
      return;
    }

    // update the license document + user document
    await Promise.all([
      setDoc(
        licenceDocRef,
        {
          patientID: currentUser.uid,
          patientEmail: currentUser.email,
          used: true,
          startDate: new Date(),
          endDate: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1),
          ),
        },
        { merge: true },
      ),
      setDoc(
        userDocRef,
        {
          license: licenseCode,
        },
        { merge: true },
      ),
    ]);
    //Update local state:
    let userDataCopy = authUserData
    userDataCopy.license = licenseCode
    setAuthUserData(userDataCopy)
    props.setLoading(false);
    
    navigate("/activity");
  }

  return (
    <>
      <div className="accountHeader">
        <h1 style={{ fontFamily: "NexaBold", fontSize: "22px" }}>My Account</h1>
        <div
          onClick={async () => {
            await signOut(auth);
            navigate("/login");
          }}
          id="backButtonContainer"
        >
          <BackIcon size={20} />
          <p style={{ paddingTop: "14px", paddingLeft: "5px" }}>Sign Out</p>
        </div>
      </div>
      <form id="providerAccountForm">
        <p style={{ fontSize: "18px", fontWeight: "600" }}>
          Account Information
        </p>
        <div className="accountFormRowContainer">
          <div className="accountInputContainer">
            <label htmlFor="firstName">First Name</label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              name="firstName"
            />
          </div>
          <div className="accountInputContainer">
            <label htmlFor="lastName">Last Name</label>
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              name="lastName"
            />
          </div>
        </div>
        <div className="accountFormRowContainer">
          <div className="accountInputContainer">
            <label htmlFor="email">Email Adress</label>
            <input
              style={{ width: "600px" }}
              disabled={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              name="email"
            />
          </div>
        </div>
        <div className="accountFormRowContainer">
          <div className="accountInputContainer">
            <label htmlFor="nameOfPractice">Name Of Practice</label>
            <input
              style={{ width: "600px" }}
              value={practice}
              onChange={(e) => setPractice(e.target.value)}
              type="text"
              name="nameOfPractice"
            />
          </div>
        </div>
        <div className="accountFormRowContainer">
          <div className="accountInputContainer">
            <label htmlFor="address">Practice Street Adress</label>
            <input
              style={{ width: "500px" }}
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              type="text"
              name="address"
            />
          </div>
          <div className="accountInputContainer">
            <label htmlFor="city/state/region/province">
              City / State / Region / Province
            </label>
            <input
              style={{ width: "250px" }}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              type="text"
              name="city/state/region/province"
            />
          </div>
        </div>
        <div className="accountFormRowContainer">
          <div className="accountInputContainer">
            <label htmlFor="country">Country</label>
            <input
              style={{ width: "500px" }}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              type="text"
              name="country"
            />
          </div>
          <div className="accountInputContainer">
            <label htmlFor="zipCode">Zip / Postal Code</label>
            <input
              style={{ width: "250px" }}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              type="text"
              name="zipCode"
            />
          </div>
        </div>
        <div className="editButtonContainer">
          <button
            onClick={handleEditInformation}
            type="submit"
            className="editButton"
          >
            Edit Information
          </button>
        </div>
      </form>
      <div className="licensesContainer">
        <p style={{ fontSize: "18px", fontWeight: "600" }}>
          License Information
        </p>
        {props.licenseData.length === 0 ? (
          <p>You have not purchased any licenses</p>
        ) : (
          <>
            <div className="licensesLabels">
              <p>License</p>
              <p>Activated</p>
              <p>Patient Email</p>
              <p>Experation</p>
            </div>
            {props.licenseData
              .sort((a, b) => {
                if (a.used === b.used) {
                  return 0;
                }
                if (a.used) {
                  return -1;
                }
                return 1;
              })
              .map((license) => (
                <LicenseInformation
                  key={license.license}
                  license={license.license}
                  activated={license.used}
                  email={license.patientEmail}
                  experationDate={license.endDate}
                  showLicenseModal={showLicenseModal}
                  setLicenseCode={setLicenseCode}
                />
              ))}
          </>
        )}
        {showLicenseModal && (
          <div style={{ paddingTop: "1rem" }}>
            <p>
              Please enter or click on one of your licenses to activate it for
              your account.
            </p>
            <input
              style={{ width: "500px" }}
              value={licenseCode}
              onChange={(e) => setLicenseCode(e.target.value)}
            />
          </div>
        )}
        <div style={{ display: "flex", gap: "2rem" }}>
          <button
            className="editButton"
            onClick={() => {
              if (showLicenseModal) {
                setLicenseCode("");
                setShowLicenseModal(false);
              } else {
                navigate("/purchase");
              }
            }}
          >
            {showLicenseModal ? "Cancel" : "Purchase Licenses"}
          </button>
          <button
            className="editButton"
            disabled={showLicenseModal && licenseCode.length === 0}
            onClick={() => {
              if (!showLicenseModal) {
                setShowLicenseModal(true);
              } else {
                handleLicenseActivation();
              }
            }}
          >
            {showLicenseModal ? "Confirm" : "Activate Licenses"}
          </button>
        </div>
      </div>
    </>
  );
}
