import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./base.js";
import {
  doc,
  getDoc,
} from "firebase/firestore";
import { SyncLoader } from "react-spinners";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [authUserData, setAuthUserData] = useState(null);
  const [pending, setPending] = useState(true);


  async function getUserAccount(user){
    if(user!=null){
    //console.log(user)
    const userDocSnap = await getDoc(doc(db, "users", user.uid));
    //console.log("userSnap",userDocSnap)
      if (!userDocSnap.exists()) {
        setAuthUserData(null)
      }
      let nuserData = userDocSnap.data();
      //console.log("userdata:",nuserData)
      setAuthUserData(nuserData)
    }
    else{
      setAuthUserData(null)
    }
  }
  useEffect(() => {
    onAuthStateChanged(auth,async (user) => {
      setPending(true)
      console.log("Auth state changed to",user)
      setCurrentUser(user)
      await getUserAccount(user)
      setPending(false)
    });
  }, []);

  if(pending) {
    return (
      <div className="loadingScreen">
        <SyncLoader color={"#e7b33e"} loading={pending} size={20} />
      </div>
    )
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        authUserData,
        setAuthUserData
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};