import CodedMovementButtons from "./SuperVisionButtons";
import { useEffect, useState } from "react";

export default function Level1_FlashingPictures(props) {
  var [image1, setImage1] = useState("Flying");
  var [dirPath, setDirPath] = useState("/BuildingBinocularity/SuperVision/");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitiesButtons">
        <CodedMovementButtons changeImage={setImage1} currImage={image1} />
      </div>
      <div
        className="vertCenter"
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <div
          style={{
            color: "white",
            fontSize: "50px",
            height: "70%",
            width: "70%",
            textAlign: "center",
          }}
        >
          <img
            className="flashingPicsImage"
            src={dirPath + image1 + ".png"}
            style={{ width: "80%", margin: "auto" }}
          />
        </div>
      </div>
    </div>
  );
}
