import "./Home.css";
import {useEffect} from 'react'
import { Nav } from "../components/home-page/nav-section";
import { HeroSection } from "../components/home-page/hero-section";
import { PlaySection } from "../components/home-page/play-section";
import { ActivityTable } from "../components/activity-table";
import { HowToSection } from "../components/home-page/how-to-section";
import { NewsSection } from "../components/home-page/news-section";
import { AboutUsSection } from "../components/home-page/about-us-section";
import { FAQSection } from "../components/home-page/faq-section";
import { ContactSection } from "../components/home-page/contact-section";
import { Footer } from "../components/home-page/footer-section";

export default function Home() {
  function highlightMatches(query) {
    // going to do something where I take the query in the search bar
    // Then search through a dictionary that corresponds with ID's of the cells
    // then change the backgrond of those specific cells perhaps
  }
  useEffect(() => {
    const scrollToHashElement = () => {
      const hash = window.location.hash;
      if (hash) {
        const id = hash.substring(1); // Remove the '#' character
        const element = document.getElementById(id);
        if (element) {
          // Scroll to the element with a delay to ensure the page has finished rendering
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' });
          }, 100);
        }
      }
    };

    // Call the function to scroll to the hash element
    scrollToHashElement();

    // Add event listener for hash changes
    const handleHashChange = () => {
      scrollToHashElement();
    };
    window.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);
  

  return (
    <div id="outerContainer">
      <Nav />
      <div style={{ maxWidth: "1200px" }}>
        <HeroSection />
        <PlaySection />
        <ActivityTable />
        <HowToSection />
        {/* <NewsSection /> */}
        <AboutUsSection />
        <FAQSection />
        <ContactSection />
      </div>
      <Footer />
    </div>
  );
}
