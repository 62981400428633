import { useEffect, useState } from "react";
import NextCheckButtons from "../Coding/nextCheckButtons";
import BlockPatternsButtons from "./blockPatternsButtons";

export default function Level5_RememberingBlockPatterns(props) {
  var [displayTime, setDisplayTime] = useState("hidden");
  var [time, setTime] = useState(1000);
  var [image, setImage] = useState("");
  const [level, setLevel] = useState("Level1");
  const [disableNext, setDisableNext] = useState(false);
  const [disableCheck, setDisableCheck] = useState(true);

  const [chosen, setChosen] = useState([]);

  useEffect(() => {
    preloadImages();
  }, [level]);

  function preloadImages() {
    const images = [];
    const max = level === 2 ? 21 : 20;
    for (let i = 1; i <= max; i++) {
      const img = new Image();
      img.src = `/Visualization/RememberingBlockPatterns/${level}/${i}.png`;
      images.push(img);
    }
    console.log("preloaded the images: ", images);
  }

  function displayPicture(buttonName) {
    if (buttonName !== "check") {
      let max;
      if (level === 2) {
        max = 21;
      } else {
        max = 20;
      }
      // let random = Math.floor(Math.random() * max) + 1;
      let randNum = selectImage(max);
      setImage(randNum);
      setDisableNext(true)
      setTimeout(() => {
        setDisplayTime("visible");
        setTimeout(() => {
          setDisplayTime("hidden");
          setDisableNext(false)
          setDisableCheck(false)
        }, time);
      }, 250);
    } else {
      setDisplayTime("visible");
      setDisableCheck(true)
    }
  }

  function selectImage(max) {
    let random = Math.floor(Math.random() * max) + 1;
    if (chosen.length === max) {
      let start = [];
      start.push(random);
      setChosen(start);
      return JSON.stringify(random);
    }
    let listCopy = chosen;
    if (!chosen.includes(random)) {
      listCopy.push(random);
      setChosen(listCopy);
      return JSON.stringify(random);
    }
    return selectImage(max);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <BlockPatternsButtons
          flashRate={setTime}
          flashBool={time}
          level={level}
          changeLevel={setLevel}
        />
      </div>
      {props.open && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "40%",
            width: "50%",
            margin: "auto",
            backgroundColor: "white",
          }}
        >
          {displayTime === "visible" && <img
            src={
              "/Visualization/RememberingBlockPatterns/" +
              level +
              "/" +
              image +
              ".png"
            }
            style={{ height: "100%" }}
          />}
        </div>
      )}
      <NextCheckButtons
        displayFunc={displayPicture}
        openActivity={props.openActivity}
        open={props.open}
        disableNext={disableNext}
        check={disableCheck}
      />
    </div>
  );
}
