import Button from "react-bootstrap/Button";
import React from "react";
import { random } from "lodash";
import MenuOptions from "./flashingWords2Buttons";

class Level6_FlashingWords2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activityComplete: false,
      activityStarted: false,
      currentIndex: 0,
      currentCorrectWord: "",
      display: 5,
      wordArrays: {
        "bake fake": ["cake", "home", "line"],
        "five live": ["mice", "gates", "soaps"],
        "nine fine": ["lines", "huge", "heats"],
        "gave same": ["name", "hide", "main"],
        "made same": ["face", "ripe", "huge"],
        "like bike": ["ride", "bear", "boat"],
        "huge cute": ["mule", "same", "rode"],
        "hope wrote": ["note", "tree", "rice"],
        "feed green": ["sheep", "same", "live"],
        "eat peas": ["please", "nice", "goat"],
        "soap boats": ["float", "cake", "five"],
        "main train": ["trail", "meat", "seed"],
        "bright night": ["light", "bee", "creek"],
        "may stay": ["away", "peach", "snail"],
        "my sly": ["fly", "steam", "loaf"],
        "may pay": ["today", "skate", "seal"],
        "dive five": ["times", "homes", "trees"],
        "gnomes phone": ["home", "hike", "read"],
        "goat coats": ["float", "name", "beat"],
        "gave brave": ["wave", "vine", "load"],
        "bees knees": ["freeze", "tune", "male"],
        "see bee": ["flee", "time", "same"],
        "snail mail": ["fails", "note", "feed"],
        "eat wheat": ["treats", "rope", "hole"],
        "lone phone": ["zone", "rake", "deep"],
      },
      wordArrays2: {
        "fly sky": ["high", "goat", "charm"],
        "nice mice": ["fly", "bone", "snake"],
        "try my": ["rice", "trade", "pale"],
        "pale whale": ["tail", "beak", "line"],
        "see me": ["read", "pane", "wise"],
        "soak soap": ["rope", "mule", "rise"],
        "boat float": ["show", "beep", "beat"],
        "snow blows": ["home", "seal", "hide"],
        "grow slow": ["dough", "green", "cane"],
        "grow slow": ["toe", "take", "rain"],
        "snakes save": ["snails", "came", "like"],
        "sleepy sheep": ["leap", "note", "road"],
        "go so": ["slow", "gate", "leap"],
        "light bright": ["kite", "peak", "away"],
        "frail snail": ["tale", "five", "note"],
        "tonight might": ["bite", "mane", "cute"],
        "may stay": ["late", "toad", "prize"],
        "made fade": ["away", "boat", "mine"],
        "bees need": ["peace", "bake", "feel"],
        "free trees": ["dream", "creek", "pipe"],
        "find kind": ["mice", "planes", "deep"],
        "mice drive": ["by", "sleep", "dream"],
        "tiny shiny": ["light", "beat", "late"],
        "knights might": ["bite", "bee", "snake"],
        "fly sky": ["high", "close", "pea"],
        "slimy grimy": ["flies", "peace", "sheep"],
        "chew new": ["shoes", "time", "beat"],
        "glue blue": ["tubes", "time", "sleep"],
        "few new": ["cubes", "woke", "wise"],
        "eight sleighs": ["play", "been", "bait"],
        "hey they": ["say", "make", "bait"],
      },
      correctWordArray: [],
      totalCorrect: 0,
      word0answer: "whitetext",
      word1answer: "whitetext",
      word2answer: "whitetext",
      word3answer: "whitetext",
      flashRate: 500,
      fontSize: "3em",
      check: false,
      level: 1,
      key: "",
      presentation: "show",
      chosen: [],
    };
    this.startActivity = this.startActivity.bind(this);
    this.clickWord = this.clickWord.bind(this);
    this.nextSet = this.nextSet.bind(this);
  }

  changeLevel = (change) => {
    this.setState({ level: change });
  };

  changePresentation = (change) => {
    this.setState({ presentation: change });
  };

  componentDidMount() {
    console.log(React.version);
    this.setState({ correctWordArray: new Array(10).fill(0) });
  }

  startActivity(e) {
    this.setState({ activityStarted: true });
    this.setState({ activityComplete: false });
    this.setState({ totalCorrect: 0 });
    this.nextSet(0);
  }

  changeFlash = (change) => {
    this.setState({ flashRate: change });
  };

  changeSize = (change) => {
    this.setState({ fontSize: change });
  };

  shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  selectIndex(keys) {
    let random = Math.floor(Math.random() * keys.length) + 1;
    console.log("this is the random num selected: ", random);
    if (this.state.chosen.length === keys.length) {
      let start = [];
      start.push(random);
      // setChosen(start);
      this.setState({ chosen: start });
      return random;
    }
    let listCopy = this.state.chosen;
    if (!this.state.chosen.includes(random)) {
      listCopy.push(random);
      this.setState({ chosen: listCopy });
      return random;
    }
    console.log("restarting");
    return this.selectIndex(keys);
  }

  nextSet(index) {
    if (index === 0) {
      // this.setState({wordArrays: this.shuffle(this.state.wordArrays)})
      this.setState({ activityStarted: true });
      this.setState({ activityComplete: false });
      this.setState({ totalCorrect: 0 });
    }
    this.setState({ activityStarted: true });
    this.setState({ currentIndex: index });

    let randomIndex;
    let currArray;
    let key;
    if (this.state.level === 1) {
      let keys = Object.keys(this.state.wordArrays);
      console.log("what's being passed: ", keys);
      randomIndex = this.selectIndex(keys);
      console.log("this is the index: ", randomIndex);
      key = keys[randomIndex];
      currArray = this.state.wordArrays[key];
    } else {
      let keys = Object.keys(this.state.wordArrays2);
      console.log("the keys: ", keys);
      randomIndex = this.selectIndex(keys);
      console.log("this is the index: ", randomIndex);
      key = keys[randomIndex];
      currArray = this.state.wordArrays2[key];
    }
    let currArrayCopy = [...currArray];
    let correctIndexOf = currArrayCopy[0];
    this.shuffle(currArrayCopy);
    let correctWordIndex = currArrayCopy.indexOf(correctIndexOf);

    // selecting the right word
    let correctWordArray = this.state.correctWordArray;
    correctWordArray[index] = correctWordIndex;

    this.setState({ currentCorrectWord: key });

    if (this.state.flashRate !== 0) {
      setTimeout(() => {
        this.setState({ display: 2 });
      }, this.state.flashRate);
    }

    this.setState({ display: 1 });
    this.setState({ word0: currArrayCopy[0] });
    this.setState({ word1: currArrayCopy[1] });
    this.setState({ word2: currArrayCopy[2] });

    this.setState({ correctWordArray: correctWordArray });
    this.setState({ key: correctIndexOf });
  }

  clickWord(e) {
    if (e === 10) {
      this.setState({ ["word" + e + "answer"]: "whitetext" });
      this.setState({ activityComplete: true });
      this.setState({ activityStarted: false });
      this.setState({ currentIndex: 0 });
    } else {
      if (this.state.correctWordArray[this.state.currentIndex] == e) {
        this.setState({ totalCorrect: this.state.totalCorrect + 1 });
        this.setState({ ["word" + e + "answer"]: "greentext" });
        this.setState({ display: 3 });
      } else {
        this.setState({ ["word" + e + "answer"]: "redtext" });
        this.setState({ display: 4 });
      }
    }

    if (this.state.currentIndex == this.state.correctWordArray.length) {
      this.setState({ activityStarted: false });
      // this.setState({["word" + e + "answer"]: "whitetext"})
      // this.setState({activityComplete: true})
      // this.setState({activityStarted: false})
      // this.setState({ currentIndex: 0 });
    } else {
      this.setState({ ["word" + e + "answer"]: "whitetext" });
      this.setState({ activityStarted: false });
      this.setState({ currentIndex: this.state.currentIndex + 1 });
    }
  }

  redo() {
    if (this.state.activityComplete) {
      this.setState({ activityComplete: false });
    }
    // setTimeout(() => {
    //   this.setState({ display: 0 });
    // }, this.state.displayTime);
    this.setState({ display: 5 });
    this.setState({ check: true });
  }

  render() {
    return (
      <div
        className="activity_box"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="activitySettingsButtonsContainer">
          <MenuOptions
            flashRate={this.changeFlash}
            flashBool={this.state.flashRate}
            changeActivity={this.changeSize}
            activityBool={this.state.fontSize}
            level={this.state.level}
            changeLevel={this.changeLevel}
            presentation={this.state.presentation}
            changePresentation={this.changePresentation}
          />
        </div>

        {this.props.open && (
          <div
            style={{
              display: "flex",
              flexDirection:
                this.state.display === 4 || this.state.display === 3
                  ? "column"
                  : "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {this.state.display == 3 && !this.state.activityComplete && (
              <div id="correctText">Correct!</div>
            )}
            {this.state.display == 4 && !this.state.activityComplete && (
              <div id="incorrectText">Incorrect!</div>
            )}
            <div style={{ display: "flex" }}>
              {(this.state.display == 1 ||
                this.state.flashRate === 0 ||
                (this.state.presentation === "recall" &&
                  (this.state.display === 3 ||
                    this.state.display === 4 ||
                    this.state.display === 5))) &&
                !this.state.activityComplete && (
                  <div
                    className="whiteTextStyle"
                    style={{
                      fontSize: this.state.fontSize,
                      marginRight:
                        this.state.display === 3 ||
                        this.state.display === 4 ||
                        this.state.display === 1
                          ? ""
                          : "3em",
                    }}
                  >
                    {this.state.currentCorrectWord}
                  </div>
                )}
              {this.state.display !== 1 &&
                this.state.display !== 2 &&
                this.state.presentation == "show" &&
                this.state.flashRate !== 0 &&
                !this.state.activityComplete && (
                  <div
                    className="whiteTextStyle"
                    style={{
                      fontSize: this.state.fontSize,
                      marginRight:
                        this.state.display === 3 || this.state.display === 4
                          ? ""
                          : "3em",
                    }}
                  >
                    {this.state.currentCorrectWord}
                  </div>
                )}
              {(this.state.display == 5 ||
                this.state.display === 3 ||
                this.state.display === 4) &&
                !this.state.activityComplete && (
                  <div
                    className="whiteTextStyle"
                    style={{
                      fontSize: this.state.fontSize,
                      marginLeft: "16px",
                    }}
                  >
                    {this.state.key}
                  </div>
                )}
            </div>
            {this.state.display == 0 && !this.state.activityComplete && (
              <div>
                <div
                  onClick={() => this.clickWord(0)}
                  id={this.state.word0answer}
                  style={{ fontSize: this.state.fontSize }}
                >
                  {this.state.word0}
                </div>
                <div
                  onClick={() => this.clickWord(1)}
                  id={this.state.word1answer}
                  style={{ fontSize: this.state.fontSize }}
                >
                  {this.state.word1}
                </div>
                <div
                  onClick={() => this.clickWord(2)}
                  id={this.state.word2answer}
                  style={{ fontSize: this.state.fontSize }}
                >
                  {this.state.word2}
                </div>
              </div>
            )}
            {this.state.activityComplete && (
              <div id="whitetext">
                You scored {this.state.totalCorrect} out of{" "}
                {this.state.correctWordArray.length}! <br />{" "}
                {this.state.totalCorrect == this.state.correctWordArray.length
                  ? "Good job!"
                  : "Keep trying!"}
              </div>
            )}
          </div>
        )}
        <div className="activityActionButtonsContainer">
          <button
            className="activityActionButton"
            disabled={this.state.activityStarted}
            onClick={() => {
              if (this.props.open) {
                if (this.state.currentIndex < 10) {
                  this.nextSet(this.state.currentIndex);
                  this.setState({ check: false });
                } else {
                  this.clickWord(10);
                }
              } else {
                this.props.openActivity(true);
              }
            }}
          >
            Next
          </button>
          <button
            className="activityActionButton"
            disabled={this.state.display !== 2 && !this.state.activityStarted}
            onClick={() => {
              if (this.props.open) {
                this.setState({ display: 0 });
              } else {
                this.props.openActivity(true);
              }
            }}
          >
            Display
          </button>
          {/* <button onClick={() => this.redo()}
                    disabled={!(this.state.display === 4)}>Check</button> */}
        </div>
      </div>
    );
  }
}

export default Level6_FlashingWords2;
