import FlashingPictureButtons from "./picturesComponent";
import { useEffect, useState } from "react";

export default function Level1_FlashingPictures(props) {
  var [displayTime, setDisplayTime] = useState("hidden");
  var [time, setTime] = useState(1000);
  var [image, setImage] = useState("");
  var [dirPath, setDirPath] = useState("/Visualization/FlashingPictures/");
  var [count, setCount] = useState(0);

  const [chosen, setChosen] = useState([]);

  function preLoadImages() {
    for (let i = 0; i < 107; i++) {
      const img = new Image();
      img.src = "/Visualization/FlashingPictures/" + i + ".jpg";
    }
  }

  useEffect(() => {
    preLoadImages();
  }, []);

  useEffect(() => {
    setDisplayTime("hidden");
  }, [time, dirPath]);

  useEffect(() => {
    if (count === 1) {
      setTimeout(() => {
        console.log("inside the delay");
        setDisplayTime("visible");
        setTimeout(() => {
          console.log("now remove it. Inside inner");
          setDisplayTime("hidden");
        }, time);
      }, 250);
    } else {
      setCount(1);
    }
  }, [image]);

  function selectImage() {
    let random = Math.floor(Math.random() * 80) + 1;
    if (chosen.length === 80) {
      let start = [];
      start.push(random);
      setChosen(start);
      return JSON.stringify(random);
    }
    let listCopy = chosen;
    if (!chosen.includes(random)) {
      listCopy.push(random);
      setChosen(listCopy);
      return JSON.stringify(random);
    }
    return selectImage();
  }

  function displayPicture(buttonName) {
    if (buttonName !== "check") {
      let randNum = selectImage();
      console.log("this is the #: ", randNum);
      setImage(randNum);
    } else {
      setDisplayTime("visible");
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <FlashingPictureButtons
          flashRate={setTime}
          flashBool={time}
          hiddenLetter={setDirPath}
          letterBool={dirPath}
          hiddenLetterDisplay="none"
          levelDisplay="none"
        />
      </div>
      {props.open && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90%",
          }}
        >
          <img
            className="flashingPicsImage"
            src={dirPath + image + ".jpg"}
            style={{ visibility: displayTime }}
          />
        </div>
      )}
      <div className="activityActionButtonsContainer">
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              displayPicture("check");
            } else {
              props.openActivity(true);
            }
          }}
        >
          Check
        </button>
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              displayPicture("next");
              setDisplayTime("hidden");
            } else {
              props.openActivity(true);
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}
