export const twoThreeLetter = [
  "is",
  "the",
  "up",
  "mum",
  "at",
  "on",
  "to",
  "am",
  "too",
  "no",
  "in",
  "and",
  "for",
  "can",
  "not",
  "you",
  "me",
  "see",
  "go",
  "are",
  "my",
  "oh",
  "he",
  "she",
  "ran",
  "way",
  "eat",
  "we",
  "out",
  "got",
  "get",
  "but",
  "run",
  "all",
  "her",
  "it’s",
  "saw",
  "now",
  "old",
  "of",
  "off",
  "one",
  "us",
  "day",
  "boy",
  "two",
  "do",
  "who",
  "by",
  "put",
  "his",
  "fly",
  "was",
  "our",
  "any",
  "has",
  "or",
  "how",
  "may",
  "use",
  "why",
  "try",
  "own",
  "far",
  "ask",
  "ate",
  "so",
  "be",
  "few",
  "won",
  "car",
  "he",
  "eye",
  "not",
  "his",
  "him",
  "box",
  "bag",
  "bug",
  "bus",
  "cot",
  "cut",
  "dog",
  "bed",
  "gum",
  "bin",
  "fox",
  "hut",
  "hop",
  "jug",
  "hot",
  "mud",
  "log",
  "rug",
  "mop",
  "run",
  "pot",
  "sun",
  "bat",
  "leg",
  "cat",
  "net",
  "kid",
  "hat",
  "peg",
  "lip",
  "jam",
  "pen",
  "pig",
  "man",
  "pet",
  "pin",
  "pan",
  "red",
  "six",
  "rat",
  "ran",
  "ten",
  "tin",
  "van",
  "men",
];

export const fourLetterA = [
  "come",
  "look",
  "here",
  "said",
  "goes",
  "down",
  "sees",
  "away",
  "home",
  "went",
  "into",
  "good",
  "they",
  "came",
  "like",
  "some",
  "your",
  "with",
  "play",
  "help",
  "back",
  "stay",
  "make",
  "walk",
  "this",
  "made",
  "have",
  "find",
  "fast",
  "then",
  "take",
  "that",
  "gone",
];

export const fourLetterB = [
  "over",
  "very",
  "hide",
  "from",
  "next",
  "want",
  "cold",
  "park",
  "says",
  "open",
  "more",
  "long",
  "jump",
  "time",
  "flew",
  "were",
  "race",
  "late",
  "move",
  "what",
  "when",
  "turn",
  "work",
  "them",
  "many",
  "ever",
  "only",
  "girl",
  "must",
  "read",
  "year",
  "same",
  "much",
  "does",
  "grow",
  "give",
  "four",
  "each",
  "been",
  "five",
  "once",
];

export const fourLetterC = [
  "sing",
  "soon",
  "tell",
  "ride",
  "love",
  "name",
  "gave",
  "hand",
  "bird",
  "blue",
  "best",
  "upon",
  "nice",
  "game",
  "left",
  "room",
  "last",
  "keep",
  "also",
  "baby",
  "word",
  "talk",
  "fair",
  "song",
  "side",
  "near",
  "real",
  "bike",
  "born",
  "life",
  "both",
  "city",
  "near",
  "even",
  "face",
  "feet",
  "hour",
  "swim",
  "tree",
  "wait",
  "woke",
  "save",
  "care",
  "food",
  "hard",
  "hear",
  "high",
  "mean",
  "near",
  "page",
  "join",
  "idea",
];

export const twoThreePairs = [
  "dog bed",
  "hot dog",
  "pet pig",
  "tin van",
  "bug bag",
  "bad fox",
  "big fly",
  "big cut",
  "red rat",
  "red fox",
  "cut lip",
  "red wig",
  "hot sun",
  "cat leg",
  "hat box",
  "kid ran",
  "eat jam",
  "big man",
  "big box",
  "tin pot",
  "big lip",
  "peg bag",
  "dog box",
  "red bug",
  "big bus",
  "hot bus",
  "red cot",
  "red dog",
  "cat bed",
  "dog bed",
  "red gum",
  "mop bin",
  "mud hut",
  "leg hop",
  "pig mud",
  "hot mud",
  "big log",
  "log jam",
  "dog rug",
  "rug rat",
  "dog run",
  "hot pot",
  "sun hat",
  "pet bat",
  "bat leg",
  "bug net",
  "hat pin",
  "big kid",
  "pen bag",
  "tin pan",
  "hot pan",
  "ten men",
  "six men",
  "dog ran",
  "use up",
  "is hot",
  "the cot",
  "her mum",
  "eat up",
  "hop on",
  "too old",
  "why not",
  "put in",
  "she won",
  "his eye",
  "so few",
  "be me",
  "one day",
  "it’s us",
  "got out",
  "ask me",
  "ran far",
  "run to",
  "two men",
  "oh my",
  "he may",
  "we use",
  "saw him",
  "see her",
  "ran at",
  "and you",
  "for us",
  "no car",
  "can eat",
  "not big",
  "go out",
  "go in",
  "who are",
  "he saw",
  "but now",
  "any boy",
  "who ate",
  "by now",
  "am old",
  "get off",
  "way out",
  "his leg",
  "was hot",
  "our fox",
  "try now",
  "how bad",
  "all men",
  "do not",
  "of my",
  "own bag",
  "has gum",
  "or hop",
];

export const fourPairsA = [
  "come here",
  "look down",
  "come into",
  "play with",
  "good home",
  "they play",
  "they walk",
  "they come",
  "come back",
  "this home",
  "this walk",
  "come away",
  "your look",
  "walk back",
  "went into",
  "stay here",
  "goes back",
  "said that",
  "sees this",
  "walk away",
  "went into",
  "good home",
  "they came",
  "play with",
  "like this",
  "have your",
  "some stay",
  "back with",
  "play fast",
  "find help",
  "make this",
  "take this",
  "look here",
  "they made",
  "have some",
  "then they",
  "take that",
  "gone home",
  "went home",
  "stay back",
  "find fast",
  "came down",
];

export const fourPairsB = [
  " Flew over",
  " hide from",
  " next year",
  " want more",
  " turn cold",
  " work late",
  " says much",
  " open once",
  " long jump",
  " work time",
  " long time",
  " long race",
  " move over",
  " next move",
  " what park",
  " says when",
  " turn from",
  " only work",
  " open them",
  "many girls",
  " only ever",
  " cold girl",
  " must read",
  " same year",
  " does once",
  " move once",
  " grow next",
  " give more",
  "four girls",
  "five girls",
  "each year",
];

export const fourPairsC = [
  "Sing last",
  "talk soon",
  "tell soon",
  "bike ride",
  "love song",
  "real name",
  "gave food",
  "nice hand",
  "blue bird",
  "best game",
  "wait upon",
  "left hand",
  "baby room",
  "last word",
  "keep near",
  "also join",
  "baby talk",
  "last word",
  "fair idea",
  "left side",
  "near side",
  "real food",
  "born soon",
  "city life",
  "both feet",
  "even sing",
  "nice face",
  "hour hand",
  "best swim",
  "last tree",
  "last room",
  "also woke",
  "nice save",
  "life care",
  "nice food",
  "high tree",
  "mean face",
  "hard life",
  "hard game",
  "hear baby",
  "near tree",
  "last page",
  "join hands",
  "best idea",
];

export const fiveLetterA = [
  " comes",
  "hungry",
  "looked",
  " happy",
  "shouted",
  "looking",
  "mother",
  "father",
  "brother",
  "sister",
  "asleep",
  " going",
  " where",
  "after",
  "cried",
  " thank",
  "coming",
  " today",
  "please",
  " again",
  "inside",
  "without",
  "making",
  "having",
  "walked",
  "playing",
  " laugh",
  "school",
];

export const fiveLetterB = [
  "climb",
  "friend",
  "taking",
  "round",
  "smile",
  "under",
  "right",
  "children",
  "bigger",
  "there",
  "house",
  "lived",
  "across",
  "three",
  "slowly",
  "called",
  "because",
  "people",
  "always",
  "could",
  "didn’t",
  "before",
  "family",
  "other",
  "watch",
  "morning",
  "ground",
  "ready",
  "would",
  "tomorrow",
  "which",
  "together",
  "these",
  "water",
  "party",
  "night",
  "Saturday",
  "Sunday",
  "found",
  "enough",
  "learn",
  "another",
  "should",
  "while",
];

export const fiveLetterC = [
  "black",
  "think",
  "towards",
  "weekend",
  "white",
  "birthday",
  "favourite",
  "sleep",
  "really",
  "above",
  "great",
  "along",
  "bring",
  "green",
  "holiday",
  "group",
  "answer",
  "almost",
  "animal",
  "dinner",
  "thought",
  "beach",
  "thing",
  "yesterday",
  "soccer",
  "breakfast",
  "world",
  "those",
  "through",
  "write",
  "young",
  "around",
  "sound",
  "start",
  "story",
  "quickly",
  "bought",
  "brown",
  "small",
  "second",
  "clothes",
  "cousin",
  "river",
  "point",
  "place",
  "excited",
  "heard",
  "minute",
  "proud",
  "around",
  "began",
  "being",
  "music",
  "scared",
  "super",
  "below",
  "between",
  "swim",
  "teacher",
  "afternoon",
  "carry",
  "change",
  "worried",
  "maybe",
  "clean",
  "police",
  "follow",
  "behind",
  "threw",
  "wanted",
  "castle",
  "large",
  "leave",
  "surprise",
  "earth",
  "sentence",
  "light",
  "different",
  "straight",
  "begin",
  "against",
  "heavy",
  "never",
  "wonder",
  "picture",
  "awesome",
  "important",
  "angry",
];

export const fivePairs = [
  "Comes towards",
  "looked hungry",
  "happy birthday",
  "happy children",
  "looked excited",
  "shouted together",
  "looking towards",
  "mother called",
  "mother wanted",
  "worried father",
  "happy brother",
  "three sisters",
  "favourite sister",
  "different father",
  "sound asleep",
  "found asleep",
  "going where",
  "after today",
  "cried because",
  "people watch",
  "always second",
  "could begin",
  "didn’t clean",
  "before breakfast",
  "please begin",
  "please think",
  "called again",
  "comes inside",
  "without dinner",
  "without clothes",
  "making clothes",
  "having breakfast",
  "walked towards",
  "playing music",
  "second brother",
  "laugh enough",
  "school holiday",
  "climb above",
  "school friend",
  "taking place",
  "taking together",
  "round smile",
  "under water",
  "threw right",
  "right teacher",
  "bigger children",
  "bigger cousin",
  "towards there",
  "around there",
  "brown house",
  "different house",
  "lived there",
  "across there",
  "leave slowly",
  "leave behind",
  "leave quickly",
  "great people",
  "could sleep",
  "before soccer",
  "before Sunday",
  "family dinner",
  "other place",
  "other cousin",
  "watch soccer",
  "watch picture",
  "Saturday morning",
  "Saturday afternoon",
  "soccer ground",
  "ready yesterday",
  "ready answer",
  "tomorrow morning",
  "which animal",
  "which teacher",
  "begin together",
  "think together",
  "these police",
  "clean water",
  "awesome party",
  "surprise party",
  "Sunday night",
  "enough clothes",
  "another sister",
  "should write",
  "should leave",
  "should start",
  "learn music",
  "while asleep",
  "never found",
  "never there",
  "angry mother",
  "important answer",
  "important castle",
  "awesome music",
  "large picture",
  "small picture",
  "black picture",
  "black clothes",
  "white clothes",
  "never wonder",
  "young father",
  "heavy animal",
  "heavy thing",
  "climb against",
  "begin holiday",
  "straight answer",
  "straight around",
  "begin playing",
  "light green",
  "holiday weekend",
  "second birthday",
  "birthday dinner",
  "really excited",
  "above ground",
  "great river",
  "great sound",
  "follow along",
  "comes along",
  "bring dinner",
  "bring children",
  "green water",
  "green clothes",
  "super holiday",
  "beach holiday",
  "small group",
  "large group",
  "excited group",
  "almost straight",
  "almost through",
  "important thought",
  "different thought",
  "heavy thing",
  "clean thing",
  "clean world",
  "wonder world",
  "awesome world",
  "those police",
  "those children",
  "through there",
  "young cousin",
  "heard sound",
  "second minute",
  "second brother",
  "start again",
  "start before",
  "start quickly",
  "awesome story",
  "bought picture",
  "point there",
  "point again",
  "great place",
  "super place",
  "excited minute",
  "scared minute",
  "proud mother",
  "proud teacher",
  "began breakfast",
  "began playing",
  "being scared",
  "being worried",
  "below there",
  "between castles",
  "carry there",
  "carry clothes",
  "carry brother",
  "change clothes",
  "write again",
  "maybe never",
  "maybe straight",
  "maybe different",
  "follow behind",
  "threw things",
  "wanted breakfast",
  "leave tomorrow",
  "large earth",
  "brown earth",
  "small sentence",
];
