import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function CodedMovementButtons(props) {
  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Koala Flash Rate
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => props.changeFlash(500)}
            active={props.flash === 500}
          >
            0.50
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeFlash(600)}
            active={props.flash === 600}
          >
            0.60
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeFlash(700)}
            active={props.flash === 700}
          >
            0.70
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeFlash(800)}
            active={props.flash === 800}
          >
            0.80
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeFlash(900)}
            active={props.flash === 900}
          >
            0.90
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeFlash(1000)}
            active={props.flash === 1000}
          >
            1.00
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeFlash(1200)}
            active={props.flash === 1200}
          >
            1.20
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeFlash(1400)}
            active={props.flash === 1400}
          >
            1.40
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeFlash(1600)}
            active={props.flash === 1600}
          >
            1.60
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeFlash(1800)}
            active={props.flash === 1800}
          >
            1.80
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeFlash(2000)}
            active={props.flash === 2000}
          >
            2.00
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Progression
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => props.changeSpeed(15)}
            active={props.speedBool === 15}
          >
            Slow Kangaroo
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeSpeed(10)}
            active={props.speedBool === 10}
          >
            Regular Kangaroo
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeSpeed(5)}
            active={props.speedBool === 5}
          >
            Fast Kangaroo
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
