import FeedTheAnimalsButtons from "./FeedTheAnimalsButtons";
import { useEffect, useState } from "react";

export default function Level6_MagicGlasses(props) {
  var [image1, setImage1] = useState("Butterfly");
  var [dirPath, setDirPath] = useState("/BuildingBinocularity/FeedTheAnimals/");
  const [margin, setMargin] = useState(160);
  const [margin2, setMargin2] = useState(200);
  const [check, setCheck] = useState(false);
  const [place, setPlace] = useState(1);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "90%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <FeedTheAnimalsButtons changeImage={setImage1} currImage={image1} setMargin={setMargin} setMargin2={setMargin2} setCheck={setCheck}/>
      </div>
      {props.open && <div style={{ height: "100%", display: "flex", alignItems: 'center', flexDirection: "column"}}>
        {image1 === "Butterfly" && (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: '100%',
              flexDirection: "column",
              justifyContent: 'center',
              marginLeft: '20px',
            }}
          >
            <div style={{ width: "100%"}}>
              <div style={{ display: "inline-block", width: "500px" }}>
                <img
                  src={dirPath + "ButterflyCyan.png"}
                  style={{ height: "250px", marginLeft: margin }}
                />
                <img
                  src={dirPath + "ButterflyRed.png"}
                  style={{ height: "250px", marginLeft: margin * -1 }}
                />
              </div>
              <div style={{ display: "inline-block", marginLeft: '-100px'}}>
                <img
                  src={dirPath + "FlowerDouble.png"}
                  style={{ height: "250px", marginTop: "350px" }}
                />
              </div>
            </div>
            <div class="slider">
              <input
                type="range"
                min="160"
                max="210"
                value={margin}
                onChange={(e) => setMargin(e.target.value * 1)}
                step="5"
                id="slider"
              />
              <div class="slider-ticks">
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
                <span>6</span>
              </div>
            </div>
          </div>
        )}
        {image1 === "Koala" && (
          <div
            style={{
              display: "flex",
              gap: "50px",
              height: "100%",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <div style={{ display: "inline-block", marginRight: "-200px" }}>
                <img
                  src={dirPath + "KoalaDouble.png"}
                  style={{ height: "350px" }}
                />
              </div>
              <div style={{ display: "inline-block" }}>
                <img
                  src={dirPath + "EucalyptusRed.png"}
                  style={{ height: "350px", marginLeft: margin }}
                />
                <img
                  src={dirPath + "EucalyptusCyan.png"}
                  style={{ height: "350px", marginLeft: margin * -1 }}
                />
              </div>
            </div>
            <div style={{ marginLeft: "300px" }}>
              <div class="slider">
                <input
                  type="range"
                  min="160"
                  max="200"
                  value={margin}
                  onChange={(e) => setMargin(e.target.value * 1)}
                  step="10"
                  id="slider"
                />
                <div class="slider-ticks">
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                </div>
              </div>
            </div>
          </div>
        )}
        {image1 === "Lorikeets" && (
          <div
            style={{
              display: "flex",
              gap: "50px",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <div
                style={{
                  display: "inline-block",
                  width: "500px",
                  marginRight: "-330px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img
                    src={dirPath + "NestlingsRed.png"}
                    style={{ height: "350px", marginLeft: margin }}
                  />
                  <img
                    src={dirPath + "NestlingsCyan.png"}
                    style={{ height: "350px", marginLeft: margin * -1 }}
                  />
                </div>
              </div>

              <div style={{ display: "inline-block", width: "500px" }}>
                <div style={{ display: "flex", backgroundColor: "" }}>
                  <img
                    src={dirPath + "LorikeetRed.png"}
                    style={{ height: "350px", marginLeft: margin2 }}
                  />
                  <img
                    src={dirPath + "LorikeetCyan.png"}
                    style={{ height: "350px", marginLeft: margin2 * -1 }}
                  />
                </div>
              </div>
            </div>

            <div style={{ display: "flex", gap: "100px" }}>
              <div class="slider">
                <input
                  type="range"
                  min="150"
                  max="200"
                  value={margin}
                  onChange={(e) => setMargin(e.target.value * 1)}
                  step="10"
                  id="slider"
                />
                <div class="slider-ticks">
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                </div>
              </div>
              <div class="slider">
                <input
                  type="range"
                  min="140"
                  max="220"
                  value={margin2}
                  onChange={(e) => setMargin2(e.target.value * 1)}
                  step="10"
                  id="slider"
                />
                <div class="slider-ticks">
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                </div>
              </div>
            </div>
          </div>
        )}
        {image1.includes("Answer") && 
          <div style={{height: '100%', textAlign: 'center', margin: 'auto', backgroundColor: 'blue'}}>
            <img src={dirPath + image1} style={{height: '100%'}}/>
          </div>
        }
        {image1.includes("Landing") && 
          <div style={{height: '100%', textAlign: 'center', margin: 'auto'}}>
            <img src={dirPath + image1 + JSON.stringify(place) + ".png"} style={{height: '100%', maxWidth: '100%'}}/>
          </div>
        }
        <div style={{height: '100%', display: 'flex', alignItems: 'flex-end', width: '100%', textAlign: 'end', justifyContent: 'flex-end'}}>
        {image1.includes("Lorikeets") && <button className="activityActionButton" onClick={() => {
            console.log("place before: ", place)
            if (image1.includes("Landing")) {
              let newImage = image1.replace("Landing", "")
              console.log(newImage)
              setImage1(newImage)
            } else if (image1.includes("Answer")) {
              setPlace(place + 1)
              const oldImage = "Answer" + JSON.stringify(place) + ".png"
              setImage1(image1.replace(oldImage, "Landing"))
            } else {
              setPlace(place + 1)
              setImage1(image1 + "Landing")
              console.log(image1)
            }
            console.log("place after: ", place)
          }} disabled={place === 3 && (!image1.includes("Landing"))}>Next</button>}
          {image1.includes("Lorikeets") && <button className="activityActionButton" onClick={() => {
            if (image1.includes("Answer")) {
              const oldImage = "Answer" + JSON.stringify(place) + ".png"
              console.log("oldImage: ", oldImage)
              setImage1(image1.replace(oldImage, ""))
            } else if (place === 1) {
              console.log("place = 1: ", image1)
              setImage1(image1 + "Landing")
            } else if (image1.includes("Landing")) {
              let newImage = image1.replace("Landing", "")
              // console.log(newImage)
              setImage1(newImage)
            } else {
              console.log("place > 1: ", image1)
              const backPlace = place - 1
              setPlace(backPlace)
              setImage1(image1 + "Landing")
              console.log(image1)
            }
            console.log("final image: ", image1)
          }} disabled={place === 1 && (image1.includes("Landing"))} style={{marginLeft: '10px', marginRight: '10px'}}>Back</button>}
          {!image1.includes("Answer") && !image1.includes("Landing") &&  <button className="activityActionButton" onClick={() => !image1.includes("Lorikeets") ? setImage1(image1 + "Answer.png") : setImage1(image1 + "Answer" + JSON.stringify(place) + ".png")}>Check</button>}
          {image1.includes("Answer") && !image1.includes("Lorikeets") && <button className="activityActionButton" onClick={() => setImage1(image1.replace("Answer.png", ""))}>Back</button>}
        </div>
      </div>}
    </div>
  );
}
