import NextCheckButtons from "../Coding/nextCheckButtons";
import ToggleOptions from "../Visualisation/rememberShapesButtons";
import { useState, useEffect } from "react";
import useSound from "use-sound";

export default function Level1_FlashingPictures(props) {
  var [displayTime, setDisplayTime] = useState("hidden");
  var [time, setTime] = useState(1000);
  var [image, setImage] = useState("");
  var [level, setLevel] = useState("Level1");
  var [last, setLast] = useState(1000);
  var [triggered, setTriggered] = useState(false);
  var [beat, setBeat] = useState(false);
  var [loop, setLoop] = useState();
  var [imageSize, setImageSize] = useState("25%");

  const [chosen, setChosen] = useState([]);

  const [play] = useSound("/clickSound.wav");

  useEffect(() => {
    clearInterval(loop);
    setTriggered(false);
  }, [beat]);

  function displayPicture(buttonName) {
    if (beat && !triggered) {
      setLoop(
        setInterval(() => {
          play();
        }, 1000),
      );
      setTriggered(true);
    }

    function selectImage() {
      let random = Math.floor(Math.random() * 20) + 1;
      console.log("the random # pulled: ", random);
      if (chosen.length === 20) {
        console.log("_______ STARTING OVER _______", chosen);
        let start = [];
        start.push(random);
        setChosen(start);
        return JSON.stringify(random);
      }
      let listCopy = chosen;
      console.log("this is list copy: ", listCopy);
      if (!chosen.includes(random)) {
        listCopy.push(random);
        console.log("list copy after the push: ", listCopy);
        setChosen(listCopy);
        console.log("chosen after the addition: ", chosen);
        return JSON.stringify(random);
      }
      console.log("going to recurse cuz ", random, " is already in the list");
      return selectImage();
    }

    setDisplayTime("hidden");
    if (buttonName !== "check") {
      // let random = Math.floor(Math.random() * 20) + 1;
      // if (random == last) {
      //   if (random > 0) {
      //     random -= 1;
      //   }
      //   if (random == 0) {
      //     random += 1;
      //   }
      // }
      // setLast(random);
      let randNum = selectImage();
      setImage(randNum);
      if (time === "continuous") {
        setDisplayTime("visible");
      } else {
        setTimeout(() => {
          setDisplayTime("visible");
          setTimeout(() => {
            setDisplayTime("hidden");
          }, time);
        }, 600);
      }
    } else {
      setDisplayTime("visible");
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <ToggleOptions
          flashRate={setTime}
          flashBool={time}
          changeLevel={setLevel}
          levelBool={level}
          useBeat={setBeat}
          beatBool={beat}
          imageBool={imageSize}
          changeImage={setImageSize}
        />
      </div>
      {props.open && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90%",
          }}
        >
          <img
            className="flashingPicsImage"
            src={
              "/Visualization/RememberingShapes/" + level + "/" + image + ".png"
            }
            style={{
              visibility: displayTime,
              height: imageSize,
              width: "auto",
            }}
          />
        </div>
      )}
      <NextCheckButtons
        displayFunc={displayPicture}
        displayCheck="inline"
        openActivity={props.openActivity}
        open={props.open}
      />
    </div>
  );
}
