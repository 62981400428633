import "./activity-table.css";
import { useRef, useState, createRef } from "react";
import { activityNames } from "../data/tableData";

export function ActivityTable(props) {
  const refsArray = activityNames.map(() => createRef());
  const [query, setQuery] = useState("");

  function resetMatches() {
    for (let i = 0; i < activityNames.length; i++) {
      refsArray[i].current.style.backgroundColor = "inherit";
    }
  }

  function findMatches() {
    let matches = [];
    for (let i = 0; i < activityNames.length; i++) {
      let activity = activityNames[i].toLowerCase();
      if (activity.includes(query.toLowerCase())) {
        matches.push(i);
      }
    }
    console.log("matches: ", matches);

    for (let i = 0; i < matches.length; i++) {
      let match = matches[i];
      console.log("ref obj: ", refsArray[match].current);
      refsArray[match].current.style.backgroundColor = "lightgreen";
    }
  }

  function clearAll() {
    for (let i = 0; i < activityNames.length; i++) {
      refsArray[i].current.style.backgroundColor = "inherit";
    }
  }

  console.log("my string: ", activityNames[0].replaceAll(" ", ""));

  return (
    <div id="activityTableWrapper">
      <div id="TSearchSection">
        <div
          className="activitySectionHeader"
          style={{ height: "100%", display: "flex", alignItems: "flex-start" }}
        >
          Activities
        </div>
        <div style={{ display: "flex" }}>
          <input
            id="tableSearch"
            type="text"
            placeholder="Search Activites"
            onChange={(e) => {
              setQuery(e.target.value);
              clearAll();
            }}
          />
          <button
            className="yellowButton"
            style={{ width: "90px" }}
            onClick={() => findMatches()}
          >
            Enter
          </button>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <table className="outerTable">
          <thead>
            <div style={{ padding: "0.5rem" }}>Coding</div>
          </thead>
          <tr>
            <th>Level 1</th>
          </tr>
          <tr
            ref={refsArray[0]}
            onClick={() => {
              props.changePdf(
                "/Coding/" + activityNames[0].replaceAll(" ", "") + ".pdf",
              );
              props.changePdf2(
                "/Coding/" + activityNames[0].replaceAll(" ", "") + "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[0]}</td>
          </tr>
          <tr
            ref={refsArray[1]}
            onClick={() => {
              props.changePdf(
                "/Coding/" + activityNames[1].replaceAll(" ", "") + ".pdf",
              );
              props.changePdf2(
                "/Coding/" + activityNames[1].replaceAll(" ", "") + "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[1]}</td>
          </tr>
          <tr className="yellBG">
            <th>Level 2</th>
          </tr>
          <tr
            ref={refsArray[2]}
            onClick={() => {
              props.changePdf(
                "/Coding/" + activityNames[2].replaceAll(" ", "") + ".pdf",
              );
              props.changePdf2(
                "/Coding/" + activityNames[2].replaceAll(" ", "") + "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[2]}</td>
          </tr>
          <tr
            ref={refsArray[3]}
            onClick={() => {
              props.changePdf(
                "/Coding/" + activityNames[3].replaceAll(" ", "") + ".pdf",
              );
              props.changePdf2(
                "/Coding/" + activityNames[3].replaceAll(" ", "") + "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[3]}</td>
          </tr>
          <tr>
            <th>Level 3</th>
          </tr>
          <tr
            ref={refsArray[4]}
            onClick={() => {
              props.changePdf(
                "/Coding/" + activityNames[4].replaceAll(" ", "") + ".pdf",
              );
              props.changePdf2(
                "/Coding/" + activityNames[4].replaceAll(" ", "") + "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[4]}</td>
          </tr>
          <tr
            ref={refsArray[5]}
            onClick={() => {
              props.changePdf(
                "/Coding/" + activityNames[5].replaceAll(" ", "") + ".pdf",
              );
              props.changePdf2(
                "/Coding/" + activityNames[5].replaceAll(" ", "") + "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[5]}</td>
          </tr>
          <tr className="yellBG">
            <th>Level 4</th>
          </tr>
          <tr
            ref={refsArray[6]}
            onClick={() => {
              props.changePdf(
                "/Coding/" + activityNames[6].replaceAll(" ", "") + ".pdf",
              );
              props.changePdf2(
                "/Coding/" + activityNames[6].replaceAll(" ", "") + "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[6]}</td>
          </tr>
          <tr className="yellBG">
            <td></td>
          </tr>
          <tr>
            <th>Level 5</th>
          </tr>
          <tr
            ref={refsArray[7]}
            onClick={() => {
              props.changePdf(
                "/Coding/" + activityNames[7].replaceAll(" ", "") + ".pdf",
              );
              props.changePdf2(
                "/Coding/" + activityNames[7].replaceAll(" ", "") + "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[7]}</td>
          </tr>
          <tr
            ref={refsArray[8]}
            onClick={() => {
              props.changePdf("/Coding/bdpq.pdf");
              props.changePdf2("/Coding/bdpqHELP.pdf");
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[8]}</td>
          </tr>
          <tr className="yellBG">
            <th>Level 6</th>
          </tr>
          <tr
            ref={refsArray[9]}
            onClick={() => {
              props.changePdf(
                "/Coding/" + activityNames[9].replaceAll(" ", "") + ".pdf",
              );
              props.changePdf2(
                "/Coding/" + activityNames[9].replaceAll(" ", "") + "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[9]}</td>
          </tr>
          <tr className="yellBG">
            <td></td>
          </tr>
          <tr className="yellBG">
            <td></td>
          </tr>
          <tr>
            <th>Level 7</th>
          </tr>
          <tr
            ref={refsArray[10]}
            onClick={() => {
              props.changePdf(
                "/Coding/" + activityNames[10].replaceAll(" ", "") + ".pdf",
              );
              props.changePdf2(
                "/Coding/" + activityNames[10].replaceAll(" ", "") + "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[10]}</td>
          </tr>
          <tr
            ref={refsArray[11]}
            onClick={() => {
              props.changePdf(
                "/Coding/" + activityNames[11].replaceAll(" ", "") + ".pdf",
              );
              props.changePdf2(
                "/Coding/" + activityNames[11].replaceAll(" ", "") + "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[11]}</td>
          </tr>
        </table>
        <table className="outerTable">
          <thead>
            <div style={{ padding: "0.5rem" }}>Visualisation</div>
          </thead>
          <tr>
            <th>Level 1</th>
          </tr>
          <tr
            ref={refsArray[12]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[12].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[12].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[12]}</td>
          </tr>
          <tr
            ref={refsArray[13]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[13].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[13].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[13]}</td>
          </tr>
          <tr className="yellBG">
            <th>Level 2</th>
          </tr>
          <tr
            ref={refsArray[14]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[14].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[14].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[14]}</td>
          </tr>
          <tr
            ref={refsArray[15]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[15].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[15].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[15]}</td>
          </tr>
          <tr>
            <th>Level 3</th>
          </tr>
          <tr
            ref={refsArray[16]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[16].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[16].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[16]}</td>
          </tr>
          <tr
            ref={refsArray[17]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[17].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[17].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[17]}</td>
          </tr>
          <tr className="yellBG">
            <th>Level 4</th>
          </tr>
          <tr
            ref={refsArray[18]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[18].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[18].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[18]}</td>
          </tr>
          <tr
            ref={refsArray[19]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[19].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[19].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[19]}</td>
          </tr>
          <tr>
            <th>Level 5</th>
          </tr>
          <tr
            ref={refsArray[20]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[20].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[20].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[20]}</td>
          </tr>
          <tr
            ref={refsArray[21]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[21].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[21].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[21]}</td>
          </tr>
          <tr className="yellBG">
            <th>Level 6</th>
          </tr>
          <tr
            ref={refsArray[22]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[22].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[22].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[22]}</td>
          </tr>
          <tr
            ref={refsArray[23]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[23].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[23].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[23]}</td>
          </tr>
          <tr
            ref={refsArray[24]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[24].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[24].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[24]}</td>
          </tr>
          <tr>
            <th>Level 7</th>
          </tr>
          <tr
            ref={refsArray[25]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[25].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[25].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[25]}</td>
          </tr>
          <tr
            ref={refsArray[26]}
            onClick={() => {
              props.changePdf(
                "/Visualization/" +
                  activityNames[26].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/Visualization/" +
                  activityNames[26].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[26]}</td>
          </tr>
        </table>
        <table className="outerTable">
          <thead>
            <div style={{ padding: "0.5rem" }}>Eye Movements</div>
          </thead>
          <tr>
            <th>Level 1</th>
          </tr>
          <tr
            ref={refsArray[27]}
            onClick={() => {
              props.changePdf(
                "/EyeMovements/" +
                  activityNames[27].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/EyeMovements/" +
                  activityNames[27].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[27]}</td>
          </tr>
          <tr
            ref={refsArray[28]}
            onClick={() => {
              props.changePdf(
                "/EyeMovements/" +
                  activityNames[28].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/EyeMovements/" +
                  activityNames[28].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[28]}</td>
          </tr>
          <tr className="yellBG">
            <th>Level 2</th>
          </tr>
          <tr
            ref={refsArray[29]}
            onClick={() => {
              props.changePdf(
                "/EyeMovements/" +
                  activityNames[29].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/EyeMovements/" +
                  activityNames[29].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[29]}</td>
          </tr>
          <tr
            ref={refsArray[30]}
            onClick={() => {
              props.changePdf(
                "/EyeMovements/" +
                  activityNames[30].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/EyeMovements/" +
                  activityNames[30].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[30]}</td>
          </tr>
          <tr>
            <th>Level 3</th>
          </tr>
          <tr
            ref={refsArray[31]}
            onClick={() => {
              props.changePdf(
                "/EyeMovements/" +
                  activityNames[31].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/EyeMovements/" +
                  activityNames[31].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[31]}</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr className="yellBG">
            <th>Level 4</th>
          </tr>
          <tr
            ref={refsArray[32]}
            onClick={() => {
              props.changePdf(
                "/EyeMovements/" +
                  activityNames[32].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/EyeMovements/" +
                  activityNames[32].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[32]}</td>
          </tr>
          <tr className="yellBG"
            ref={refsArray[33]}
            onClick={() => {
              props.changePdf(
                "/EyeMovements/" +
                  activityNames[33].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/EyeMovements/" +
                  activityNames[33].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}>
            <td>{activityNames[33]}</td>
          </tr>
          <tr>
            <th>Level 5</th>
          </tr>
          <tr
            ref={refsArray[34]}
            onClick={() => {
              props.changePdf(
                "/EyeMovements/" +
                  activityNames[34].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/EyeMovements/" +
                  activityNames[34].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[34]}</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr className="yellBG">
            <th>Level 6</th>
          </tr>
          <tr
            ref={refsArray[35]}
            onClick={() => {
              props.changePdf(
                "/EyeMovements/" +
                  activityNames[35].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/EyeMovements/" +
                  activityNames[35].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
            className="yellBG"
          >
            <td>{activityNames[35]}</td>
          </tr>
          <tr className="yellBG">
            <td></td>
          </tr>
          <tr>
            <th>Level 7</th>
          </tr>
          <tr
            ref={refsArray[36]}
            onClick={() => {
              props.changePdf(
                "/EyeMovements/" +
                  activityNames[36].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/EyeMovements/" +
                  activityNames[36].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[36]}</td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </table>
        <table className="outerTable">
          <thead>
            <div style={{ padding: "0.5rem" }}>Building Binocularity</div>
          </thead>
          <tr>
            <th>Level 1</th>
          </tr>
          <tr
            ref={refsArray[37]}
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[37].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[37].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[37]}</td>
          </tr>
          <tr
            ref={refsArray[38]}
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[38].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[38].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[38]}</td>
          </tr>
          <tr className="yellBG">
            <th>Level 2</th>
          </tr>
          <tr
            ref={refsArray[39]}
            className="yellBG"
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[39].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[39].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[39]}</td>
          </tr>
          <tr
            ref={refsArray[40]}
            className="yellBG"
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[40].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[40].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[40]}</td>
          </tr>
          <tr>
            <th>Level 3</th>
          </tr>
          <tr
            ref={refsArray[41]}
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[41].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[41].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[41]}</td>
          </tr>
          <tr
            ref={refsArray[42]}
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[42].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[42].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[42]}</td>
          </tr>
          <tr className="yellBG">
            <th>Level 4</th>
          </tr>
          <tr
            ref={refsArray[43]}
            className="yellBG"
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[43].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[43].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[43]}</td>
          </tr>
          <tr
            ref={refsArray[44]}
            className="yellBG"
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[44].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[44].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[44]}</td>
          </tr>
          <tr>
            <th>Level 5</th>
          </tr>
          <tr
            ref={refsArray[45]}
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[45].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[45].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[45]}</td>
          </tr>
          <tr
            ref={refsArray[46]}
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[46].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[46].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[46]}</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr className="yellBG">
            <th>Level 6</th>
          </tr>
          <tr
            ref={refsArray[47]}
            className="yellBG"
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[47].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[47].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[47]}</td>
          </tr>
          <tr
            ref={refsArray[48]}
            className="yellBG"
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[48].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[48].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[48]}</td>
          </tr>
          <tr className="yellBG">
            <td></td>
          </tr>
          <tr>
            <th>Level 7</th>
          </tr>
          <tr
            ref={refsArray[49]}
            onClick={() => {
              props.changePdf(
                "/BuildingBinocularity/" +
                  activityNames[49].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/BuildingBinocularity/" +
                  activityNames[49].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[49]}</td>
          </tr>
        </table>
        <table className="outerTable">
          <thead>
            <div style={{ padding: "0.5rem" }}>
              Visually Directed Fine Motor
            </div>
          </thead>
          <tr>
            <th>Level 1</th>
          </tr>
          <tr
            ref={refsArray[50]}
            onClick={() => {
              props.changePdf(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[50].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[50].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[50]}</td>
          </tr>
          <tr className="yellBG">
            <th>Level 2</th>
          </tr>
          <tr
            ref={refsArray[51]}
            className="yellBG"
            onClick={() => {
              props.changePdf(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[51].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[51].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[51]}</td>
          </tr>
          <tr>
            <th>Level 3</th>
          </tr>
          <tr
            ref={refsArray[52]}
            onClick={() => {
              props.changePdf(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[52].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[52].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[52]}</td>
          </tr>
          <tr className="yellBG">
            <th>Level 4</th>
          </tr>
          <tr
            ref={refsArray[53]}
            className="yellBG"
            onClick={() => {
              props.changePdf(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[53].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[53].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[53]}</td>
          </tr>
          <tr className="yellBG">
            <td></td>
          </tr>
          <tr>
            <th>Level 5</th>
          </tr>
          <tr
            ref={refsArray[54]}
            // className="yellBG"
            onClick={() => {
              props.changePdf(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[54].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[54].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[54]}</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr className="yellBG">
            <th>Level 6</th>
          </tr>
          <tr
            ref={refsArray[55]}
            className="yellBG"
            onClick={() => {
              props.changePdf(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[55].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[55].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[55]}</td>
          </tr>
          <tr className="yellBG">
            <td></td>
          </tr>
          <tr>
            <th>Level 7</th>
          </tr>
          <tr
            ref={refsArray[56]}
            // className="yellBG"
            onClick={() => {
              props.changePdf(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[56].replaceAll(" ", "") +
                  ".pdf",
              );
              props.changePdf2(
                "/VisuallyDirectedFineMotor/" +
                  activityNames[56].replaceAll(" ", "") +
                  "HELP.pdf",
              );
              props.closeDrawer(false);
            }}
          >
            <td>{activityNames[56]}</td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </table>
      </div>
    </div>
  );
}
