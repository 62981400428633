export const HowToSection = () => {
  return (
    <div id="howToSectionContainer">
      <div id="howToSectionTextContainer">
        <div style={{ paddingBottom: "2rem" }} className="sectionHeader">
          How To Use
        </div>
        <p className="howToBodyText">
          Start at the top of each column, and give the activity a go. If it is
          easy, skip down a level (or two) until you find your sweet spot -
          neither too hard, nor too easy. Then progress through to master them
          all!
        </p>
        <p howToBodyText>
          Piggyback incorporates five essential visual skills for reading
          readiness:
        </p>
        <p className="boldHeader">Coding</p>
        <p className="howToBodyText">
          Written language is a series of squiggly lines encoded for meaning.
          Piggyback joyfully extends coding skills with specific games.
        </p>
        <p className="boldHeader">Visualization</p>
        <p className="howToBodyText">
          The ability to hold onto information we see allows us to recall words,
          and imagine a world held within the pages of a book.
        </p>
        <p className="boldHeader">Eye Movements</p>
        <p className="howToBodyText">
          Efficient reading requires the ability to place our eyes at the
          correct place at the right time.
        </p>
        <p className="boldHeader">Binocularity</p>
        <p className="howToBodyText">
          Using both eyes together brings depth and wonder to our envisioned
          world.
        </p>
        <p className="boldHeader">Visually Directed Fine Motor</p>
        <p className="howToBodyText">
          Piggyback develops visual accuracy for interacting with pencils,
          keyboards and touchscreens.
        </p>
      </div>
      <div>
        <img src={"/birdbutterflygraphic.jpg"} style={{width: '100%'}}/>
      </div>
    </div>
  );
};
