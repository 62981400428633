import "./Account.css";

import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth";

import { auth, db } from "../base";
import {
  doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
} from "firebase/firestore";

import { Nav } from "../components/home-page/nav-section";
import { Footer } from "../components/home-page/footer-section";
import { ProviderAccount } from "../components/account-page/ProviderAccount";
import { PatientAccount } from "../components/account-page/PatientAccount";
import { AdminAccount } from "../components/account-page/AdminAccount";
import { SyncLoader } from "react-spinners";

export default function Account() {
  const { currentUser, authUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [licenseData, setLicenseData] = useState(null);

  useEffect(() => {
    const featchData = async (userId) => {
      setLoading(true);
      let userData = authUserData;
      // if admin get all licenses and email subscriptions
      console.log(userData)
      if (userData.userType === "admin") {
        const licenseQuerySnapshot = await getDocs(collection(db, "licenses"));
        const subscribersQuerySnapshot = await getDocs(
          collection(db, "subscribers"),
        );
        setLicenseData(licenseQuerySnapshot.docs.map((doc) => doc.data()));
        userData["subscribers"] = subscribersQuerySnapshot.docs.map((doc) =>
          doc.data(),
        );
        setUserData(userData);
      } else {
        // if provider or patient get licenses and email subscriptions for that user
        const q = query(
          collection(db, "licenses"),
          where(`${userData.userType}ID`, "==", userId),
        );
        const licenseQuerySnapshot = await getDocs(q);
        const licenseData = licenseQuerySnapshot.docs.map((doc) => doc.data());
        setLicenseData(licenseData);
        setUserData(userData);
      }
      setLoading(false);
    };
    if (currentUser && authUserData) {
      featchData(currentUser.uid);
    } else {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  if (!userData || loading) {
    return (
      <div className="loadingScreen">
        <SyncLoader color={"#e7b33e"} loading={loading} size={20} />
      </div>
    );
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Nav />
      {userData.userType === "admin" && (
        <AdminAccount
          userData={userData}
          licenseData={licenseData}
          setUserData={setUserData}
          setLoading={setLoading}
        />
      )}
      {userData.userType === "provider" && (
        <ProviderAccount
          userData={userData}
          licenseData={licenseData}
          setUserData={setUserData}
          setLoading={setLoading}
        />
      )}
      {userData.userType === "patient" && (
        <PatientAccount
          userData={userData}
          licenseData={licenseData}
          setUserData={setUserData}
          setLoading={setLoading}
        />
      )}
      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </div>
  );
}
