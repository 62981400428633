import { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function MazeOptions(props) {
  useEffect(() => {
    props.changePdf("/Visualization/Tangrams/Level1/TangramLevelOneHELP.pdf");
  }, []);

  return (
    <div style={{ display: "flex", gap: '1rem' }}>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Progression
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level1");
              props.changeImage("1");
              props.setGroup("A");
            }}
            active={props.levelBool === "Level1"}
          >
            {"Level 1: Short & Sweet"}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level2");
              props.changeImage("1");
              props.setGroup("A");
            }}
            active={props.levelBool === "Level2"}
          >
            Level 2: Trail Blazer
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level3");
              props.changeImage("1");
              props.setGroup("A");
            }}
            active={props.levelBool === "Level3"}
          >
            Level 3: Super Messy
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Group
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => {
              props.setGroup("A");
              props.setCount(1)
            }}
            active={props.group === "A"}
          >
            Group A
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.setGroup("B");
              props.levelBool === "Level3" ? props.setCount(5) : props.setCount(6)
            }}
            active={props.group === "B"}
          >
            Group B
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
