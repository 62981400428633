import { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function TangramOptions(props) {
  useEffect(() => {
    props.changePdf("/Visualization/Tangrams/Level1/TangramLevelOneHELP.pdf");
  }, []);

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Progression
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level1");
              props.changePdf(
                "/Visualization/Tangrams/Level1/TangramLevelOneHELP.pdf",
              );
              props.changeImage("House");
            }}
            active={props.levelBool === "Level1"}
            style={{ display: props.levelDisplay === "/Visualization/Tangrams/Tangrams" ? "block" : "none" }}
          >
            Level 1
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level2");
              props.changeImage("Butterfly");
            }}
            active={props.levelBool === "Level2"}
            style={{ display: props.levelDisplay === "/Visualization/Tangrams/Tangrams" ? "block" : "none" }}
          >
            Level 2
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level3");
              props.changeImage("Landing");
            }}
            active={props.levelBool === "Level3"}
            style={{ display: props.levelDisplay === "/Visualization/Tangrams/Tangrams2" ? "block" : "none" }}
          >
            Level 1
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level4");
              props.changeImage("Landing");
            }}
            active={props.levelBool === "Level4"}
            style={{ display: props.levelDisplay === "/Visualization/Tangrams/Tangrams2" ? "block" : "none" }}
          >
            Level 2
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.FlashRateDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Picture
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          {props.levelBool === "Level1" && (
            <div>
              <Dropdown.Item
                onClick={() => props.changeImage("House")}
                active={props.imageBool === "House"}
              >
                House
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Bird")}
                active={props.imageBool === "Bird"}
              >
                Bird
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Tugboat")}
                active={props.imageBool === "Tugboat"}
              >
                Tugboat
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Alien")}
                active={props.imageBool === "Alien"}
              >
                Alien
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Volcano")}
                active={props.imageBool === "Volcano"}
              >
                Volcano
              </Dropdown.Item>
            </div>
          )}

          {props.levelBool === "Level2" && (
            <div>
              <Dropdown.Item
                onClick={() => props.changeImage("Butterfly")}
                active={props.imageBool === "Butterfly"}
              >
                Butterfly
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Platypus")}
                active={props.imageBool === "Platypus"}
              >
                Platypus
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Bat")}
                active={props.imageBool === "Bat"}
              >
                Bat
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Koala")}
                active={props.imageBool === "Koala"}
              >
                Koala
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Sugar Glider")}
                active={props.imageBool === "Sugar Glider"}
              >
                Sugar Glider
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Fish")}
                active={props.imageBool === "Fish"}
              >
                Fish
              </Dropdown.Item>
            </div>
          )}
          {props.levelBool === "Level3" && (
            <div>
              <Dropdown.Item
                onClick={() => props.changeImage("Sea Turtle")}
                active={props.imageBool === "Sea Turtle"}
              >
                Sea Turtle
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Dingo")}
                active={props.imageBool === "Dingo"}
              >
                Dingo
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Pelican")}
                active={props.imageBool === "Pelican"}
              >
                Pelican
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Dolphin")}
                active={props.imageBool === "Dolphin"}
              >
                Dolphin
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Kangaroo")}
                active={props.imageBool === "Kangaroo"}
              >
                Kangaroo
              </Dropdown.Item>
            </div>
          )}

          {props.levelBool === "Level4" && (
            <div>
              <Dropdown.Item
                onClick={() => props.changeImage("Dinosaur")}
                active={props.imageBool === "Dinosaur"}
              >
                Dinosaur
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Crocodile")}
                active={props.imageBool === "Crocodile"}
              >
                Crocodile
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Emu")}
                active={props.imageBool === "Emu"}
              >
                Emu
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Dog")}
                active={props.imageBool === "Dog"}
              >
                Dog
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Frilled Lizard")}
                active={props.imageBool === "Frilled Lizard"}
              >
                Frilled Lizard
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Penguin")}
                active={props.imageBool === "Penguin"}
              >
                Penguin
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeImage("Wombat")}
                active={props.imageBool === "Wombat"}
              >
                Wombat
              </Dropdown.Item>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.hiddenLetterDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Hidden Letter
        </Dropdown.Toggle>
        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() =>
              props.hiddenLetter("/VisualThinking/FlashingPicturesLetters/")
            }
            active={
              props.letterBool === "/VisualThinking/FlashingPicturesLetters/"
            }
          >
            Use Hidden Letter
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              props.hiddenLetter("/VisualThinking/FlashingPictures/")
            }
            active={props.letterBool === "/VisualThinking/FlashingPictures/"}
          >
            Don't Use Hidden Letter
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
