import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function MagicGlassButtons(props) {
  return (
    <div style={{ display: "flex" }}>
      <Dropdown className="dropDownStyle">
        <Dropdown.Toggle style={toggleStyle}>Progression</Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => {props.changeImage("Butterfly"); props.setMargin(160); props.setCheck(false)}}
            active={props.currImage.includes("Butterfly")}
          >
            Level 1: Thirsty Butterfly
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {props.changeImage("Koala"); props.setMargin(160); props.setCheck(false)}}
            active={props.currImage.includes("Koala")}
          >
            Level 2: Give Koala her green leaves
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {props.changeImage("LorikeetsLanding"); props.setMargin(160); props.setMargin2(200); props.setCheck(false)}}
            active={props.currImage.includes("Lorikeets")}
          >
            Level 3: Help feed the Rainbow Lorikeets
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
